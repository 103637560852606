import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "./App.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import LoginPage from "./components/LoginPage";
import NewAccount from "./components/NewAccount";
import Service_Screen from "./screens/service_screen";
import OrderPage from "./screens/OrderPage";
import { LocationProvider } from "./components/LocationContext";
//import { CartProvider } from './components/CartContext';
import SampleMechanic from "./screens/SampleMechanic";
import CartScreen from "./screens/CartScreen";
import CheckoutScreen from "./screens/CheckoutScreen";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import OnTime from "./screens/OnTime";
import OnTimeScheduleService from "./screens/OnTimeScheduleService.js";
import { LocationWrapper } from "./context/LocationContext";
import ProtectedRoutes from "./utils/ProtectedRoutes";
import { CartProvider } from "./context/CartContext";
import ProtectedServiceRoutes from "./utils/ProtectedServiceRoute";
import WishlistScreen from "./screens/WishlistScreen";
import { WishlistProvider } from "./context/WishlistContext";
import ServiceCommerce from "./screens/ServiceCommerce";
import { Toaster } from "react-hot-toast";
import About from "./screens/About";
import LearnMore from "./screens/LearnMore";
import SubscriptionPlan from "./screens/SubscriptionPlan";
import PrivacyPolicy from "./screens/PrivacyPolicy";
import Terms from "./screens/TermsOfService";
import ServicePage from "./screens/ServicePage";
import HMStore from "./screens/HMStore";
import ProfilePage from "./components/ProfilePage";
import ServiceInfo from "./screens/ServiceInfo";
// import ShoppingCart from './components/ShoppingCart';
import CartInfo from "./screens/CartInfo";
import Popularpairing from "./components/popularpairing";
import ServiceDetailsScreen from "./screens/ServiceDetailsScreen";
import TrackPage from "./screens/TrackPage";
import NotificationScreen from "./screens/notificationscreen";
import Paymentscreen from "./screens/paymentscreen";
import ServiceViewAll from "./screens/ServiceViewAll";
import PersonalInformation from "./components/PersonalInformation";
import ServicePageNew from "./screens/ServicePageNew";
import ServiceViewAllNew from "./screens/ServiceViewAllNew";
import OrderHistory from "./components/orderHistory";
import OrderDetails from "./screens/OrderDetails";
import Sucesspage from "./screens/Sucesspage";
import Scheduleservice from "./screens/Scheduleservice";
import Schedulepayment from "./screens/Schedulepayment";
import Sucessscreen from "./screens/Sucessscreen";
import MechanicDetails from "./screens/MechanicDetails";
import CancelService from "./screens/cancelService";
import Settings from "./screens/Settings";
import OntimeServiceBooked from "./screens/OntimeServiceBooked";
import Trackorder from "./screens/Trackorder";
import Trackorderservices from "./screens/Trackorderservices";
import Trackorderservicedetail from "./screens/Trackorderservicedetail";
import Todetail from "./components/Todetail";
import Trackorderstep5 from "./components/trackorderstep5";
import Myvehicle from "./screens/MyVehicle";
import Chat from "./screens/Chat";
import ServiceDetails from "./components/ServiceDetails.js"
import HelpAndSupport from "./components/HelpAndSupport.js";
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <LocationWrapper>
    <CartProvider>
      <WishlistProvider>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Toaster />

          <BrowserRouter>
            <Routes>
              <Route
                path="/"
                element={
                  <LocationProvider>
                    <App />
                  </LocationProvider>
                }
              />
              {/* <Route
                exact
                path="/details"
                element={<ServiceDetails/>}
              /> */}
              <Route path="/LoginPage" element={<LoginPage />} />
              <Route path="/Newaccount" element={<NewAccount />} />
              {/* <Route path='/ProfilePage' element={<ProfilePage />} /> */}
              <Route
                exact
                path="/profile/personalInformation"
                element={<PersonalInformation />}
              />
              <Route exact path="/profile/chat" element={<Chat/>}/>
              <Route
                exact
                path="/service/:id"
                element={<ProtectedServiceRoutes />}
              >
                <Route
                  path="/service/:id"
                  element={
                    <LocationProvider>
                      <Service_Screen />
                    </LocationProvider>
                  }
                />
              </Route>

              <Route exact path="/" element={<ProtectedRoutes />}>
                <Route path="/order" element={<OrderPage />} />
              </Route>
              <Route
                exact
                path="/profile/order-history"
                element={<ProtectedRoutes />}
              >
                <Route
                  path="/profile/order-history"
                  element={<OrderHistory />}
                />
                
              </Route>
              <Route
                exact
                path="/profile/order-history/order-details"
                element={<ProtectedRoutes />}
              >
                <Route
                  path="/profile/order-history/order-details"
                  element={<OrderDetails />}
                />
              </Route>
              
              <Route exact path="/" element={<ProtectedRoutes />}>
                <Route
                  path="/profile/order-history/order-details/track"
                  element={<TrackPage />}
                />
              </Route>
              <Route
                  path="/profile/SubscriptionPlan"
                  element={<SubscriptionPlan />}
                />

              <Route exact path="/checkout" element={<ProtectedRoutes />}>
                <Route path="/checkout" element={<CheckoutScreen />} />
              </Route>
                 



                 {/* maine kiya h  */}
                 <Route
                exact
                path="/profile/help-support"
                element={<ProtectedRoutes />}
              >
                <Route
                  path="/profile/help-support"
                  element={<HelpAndSupport />}
                />
                
              </Route>
              <Route path="/mechanic/details" element={<MechanicDetails />} />
              <Route path="/cancel/service/" element={<CancelService />} />
              <Route path="/mechanic" element={<SampleMechanic />} />
              <Route path="/cart" element={<CartScreen />} />
              <Route
                path="/Trackorderservice"
                element={<Trackorderservices />}
              />
              <Route
                path="/Trackorderservicedetail"
                element={<Trackorderservicedetail />}
              />

              <Route path="/CartInfo" element={<ProtectedRoutes />}>
                <Route path="/CartInfo" element={<CartInfo />} />
              </Route>
              <Route
                path="/profile/Notifications"
                element={<ProtectedRoutes />}
              >
                <Route
                  path="/profile/Notifications"
                  element={<NotificationScreen />}
                />
              </Route>
              <Route path="/paymentscreen" element={<ProtectedRoutes />}>
                <Route path="/paymentscreen" element={<Paymentscreen />} />
              </Route>

              <Route path="/Sucesspage" element={<Sucesspage />} />
              <Route path="/Scheduleservice" element={<Scheduleservice />} />
              <Route path="/Schedulepayment" element={<Schedulepayment />} />
              <Route path="/Sucessscreen" element={<Sucessscreen />} />
              <Route path="/Popularpairing" element={<Popularpairing />} />
              <Route path="/profile/Settings" element={<Settings />} />
              <Route path="/Trackorder" element={<Trackorder />} />
              <Route path="/trackorderstep5" element={<Trackorderstep5 />} />

              <Route path="/ontime" element={<OnTime />} />
              <Route path="/OnTimeScheduleService" element={<OnTimeScheduleService/>} />

              <Route path="/About" element={<About />} />
              <Route path = "/About/LearnMore" element={<LearnMore />} />

              <Route path="/ServicePage" element={<ServicePageNew />} />
              <Route path="/ServicePage/:id" element={<ServiceViewAllNew />} />
              <Route path="/ServiceInfo" element={<ServiceInfo />} />
              <Route path="/ServiceViewAll" element={<ServiceViewAll />} />

              <Route path="/ServicePage/1" element={<ServiceDetailsScreen />} />
              <Route
                path="/Trackorderservicedetail/:serviceId"
                element={<Todetail />}
              />

              <Route path="/PrivacyPolicy" element={<PrivacyPolicy />} />

              <Route path="/HMStore" element={<HMStore />} />

              <Route path="/profile/terms-conditions" element={<Terms />} />
              <Route path="/profile/my-vehicle" element={<Myvehicle />} />

              <Route path="/wishlist" element={<WishlistScreen />} />
              <Route path="/track" element={<TrackPage />} />

              <Route
                path="/ontimeServiceBooked"
                element={<OntimeServiceBooked />}
              ></Route>
            </Routes>
          </BrowserRouter>
        </LocalizationProvider>
      </WishlistProvider>
    </CartProvider>
  </LocationWrapper>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
