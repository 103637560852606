// export const cartReducer = (state, action) => {
//   // datA I.E CART
//   switch (action.type) {  
//     case 'SET_CART':
//       return action.payload;
//     case 'ADD_TO_CART':
//       const existingItemIndex = state.findIndex(item => item._id === action.payload._id);

//       if (existingItemIndex !== -1) {
//         // If the item already exists in the cart, update its quantity
//         const updatedState = [...state];
//         updatedState[existingItemIndex].quantity += 1;
//         return updatedState;
//       } else {
//         // If the item doesn't exist in the cart, add it with a quantity of 1
//         return [...state, { ...action.payload, quantity: 1 }];
//       }

//       // case 'ADD_TO_CART':
//       //   let updatedState = [...state]; // Create a copy of the current state (cart)
  
//       //   // If payload is an array of items, loop through and add/update each one
//       //   action.payload.forEach((item) => {
//       //     const existingItemIndex = updatedState.findIndex(
//       //       (cartItem) => cartItem._id === item._id
//       //     );
  
//       //     if (existingItemIndex !== -1) {
//       //       // If the item already exists in the cart, update its quantity
//       //       updatedState[existingItemIndex].quantity += 1;
//       //     } else {
//       //       // If the item doesn't exist in the cart, add it with a quantity of 1
//       //       updatedState.push({ ...item, quantity: 1 });
//       //     }
//       //   });
  
//       //   return updatedState;
   
    
//     case 'UPDATE_QUANTITY':
//       return state.map(item =>
//         item.id === action.payload.id ? { ...item, quantity: action.payload.quantity } : item
//       );

//     case 'REMOVE_FROM_CART':
//       return state.filter(item => item._id !== action.payload._id);
//       case 'SET_SERVICES':
//         return {
//           ...state,
//           cart: state.payload,
//         };
//     case 'CLEAR_CART':
//         return []; 
//     default:
//       return state;
//   }
// };




export const cartReducer = (state, action) => {
  switch (action.type) {
 
      case 'SET_CART':
        return Array.isArray(action.payload) ? action.payload : state;

    case 'ADD_TO_CART':
     
      return [...state, { ...action.payload }];

    case 'REMOVE_FROM_CART':
      return state.filter(item => item?._id !== action.payload?._id);

      case 'CLEAR_CART':
        console.log("Clearing cart");
        return [];
      



    default:
      return state;
  }
};

