/* eslint-disable react/style-prop-object */
import React, {
  useCallback,
  useState,
  useContext,
  useEffect,
  useRef,
} from "react";
import Logo from "../assets/Logo.png";
import "../styles/navbar.css";
import { motion, useAnimation } from "framer-motion";
import classNames from "classnames";
import { Link ,  useNavigate } from "react-router-dom";

import { LocationContext } from "./LocationContext";
// import "@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css";
import Slider from "react-slick";
//import 'react-map-gl-geocoder/dist/mapbox-gl-geocoder.css';

import { useDropzone } from "react-dropzone";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import VehicleSelector from "./VehicleSelector";
import mixpanel from "../config/mixpanel";
import CancelIcon from "@mui/icons-material/Cancel";
import OopsIcon from "../assets/addVehicle/Oops.png";
import toast from "react-hot-toast";
const variants = {
  hidden: { opacity: 0.5, scale: 1, x: "80%" },
  visible: { opacity: 1, scale: 1, transition: { duration: 1 }, x: "0%" },
};

const variantsMain = {
  hidden: { opacity: 0.5, scale: 1, x: "80%" },
  visible: { opacity: 1, scale: 1, transition: { duration: 1.2 }, x: "0%" },
};

var settings = {
  classNames: "center",
  centerMode: true,
  infinite: true,
  centerPadding: "45px",
  slidesToShow: 1,
  speed: 500,
};


const Form = () => {
  const [mechanicSelected, setMechanicSelected] = useState(true);
  const [cleanerSelected, setCleanerSelected] = useState(false);
  const [driverSelected, setDriverSelected] = useState(false);
  const [locationSearched, setLocationSearched] = useState(false);

  const { latitude, longitude, updateLocation } = useContext(LocationContext);
  //const geocoderContainerRef = useRef(null);
  //const geocoderRef = useRef(null);
  const [suggestions, setSuggestions] = useState([]);
  const [inputValue, setInputValue] = useState("");
 
  // const [showPopup, setShowPopup] = useState(true);
  // const modalRef = useRef(null);
  // const [editMode, setEditMode] = useState(false);
  // const [showAddVehicleModal, setShowAddVehicleModal] = useState(false);
  // const [vehicleData, setVehicleData] = useState({
  //   brandId: "",
  //   brandName: "",
  //   modelId: "",
  //   modelName: "",
  //   vehicleNo: "",
  //   modelYear: "",
  //   RCname: "",
  //   RCnumber: "",
  //   RCScan: "default_rc_scan.jpg",
  // });
  // const handleLocationSearch = async (query) => {
  //   setInputValue(query); // Update the input field value
  //   try {
  //     const response = await fetch(
  //       `https://api.mapbox.com/geocoding/v5/mapbox.places/${encodeURIComponent(
  //         query
  //       )}.json?access_token=pk.eyJ1IjoiaGVscHltb3RvIiwiYSI6ImNsamNscHVuejAyOXAzZG1vNXppYnM1NzkifQ.BB9fpPJb9eDpRJkWwkRHXA&type=address&autocomplete=true&limit=5&country=IN`
  //     );

  //     if (!response.ok) {
  //       throw new Error("Error fetching location data");
  //     }

  //     const data = await response.json();

  //     if (data.features && data.features.length > 0) {
  //       const [lng, lat] = data.features[0].center;

  //       setSuggestions(data.features);
  //     } else {
  //       setSuggestions([]);
  //     }

  //     // Set locationSearched to true since the user has performed a location search
  //     setLocationSearched(true);
  //   } catch (error) {
  //     console.error("Error searching location:", error);
  //   }
  // };
  // const openAddVehicleModal = () => {
  //   setShowPopup(!showPopup);
  //   setEditMode(false);
  //   setShowAddVehicleModal(true);

  //   setVehicleData({
  //     brandId: "",
  //     brandName: "",
  //     modelId: "",
  //     modelName: "",
  //     vehicleNo: "",
  //     modelYear: "",
  //     RCname: "",
  //     RCnumber: "",
  //     RCScan: "default_rc_scan.jpg",
  //   });
  // };
  // const closePopup = () => {
  //   setShowPopup(false);
  // };
   const vehicleRef=useRef(null);
  const navigate = useNavigate();

// Function to handle button click
const handleClickSchedule = () => {
  const vehicleData = JSON.parse(localStorage.getItem("vehicle-selected"));

  // Check if vehicleData is an object and the 'brand' property exists
  console.log("vehile data is",vehicleData);
  const isItembrand = vehicleData && vehicleData.brandName !== undefined;

  const isItemmodel=vehicleData && vehicleData.modelName !== undefined;

  const isItemfuel=vehicleData && vehicleData.fuelType !== undefined;

  if (isItembrand==false) {
    // Navigate to the ServicePage
    // alert("Please select a vehicle first.");
  
    console.log("inside the brand part");
    console.log(vehicleRef.current);
    if (vehicleRef.current) {
       toast.error("Please Select your Vehicle first before Scheduling a service");
      
       vehicleRef.current.scrollIntoView({ behavior: "smooth" });
      vehicleRef.current.focus(); // Focus on the model input if not selected
    }
    } 
    else if(isItemmodel==false)
      {
        // alert("Please select a vehicle model.");
    toast.error("Please select a vehicle model before Scheduling a service.");
    console.log("inside thew model part");
    if (vehicleRef.current) {

      vehicleRef.current.scrollIntoView({ behavior: "smooth" });
      vehicleRef.current.focus(); // Focus on the model input if not selected
    }
  
   }
   else if(isItemfuel==false)
   {
    //  alert("Please select a vehicle fuel type.");
     console.log("inside thew fuel part");
    if (vehicleRef.current) {

      vehicleRef.current.scrollIntoView({ behavior: "smooth" });
      vehicleRef.current.focus(); // Focus on the model input if not selected
      toast.error("Please select a fuel type before Scheduling a service.");
    }
   }
   else
   {
    navigate("/ServicePage");
   }  
  
};
  const handleSelectSuggestion = (suggestion) => {
    setInputValue(suggestion.place_name);
    updateLocation(suggestion.center[1], suggestion.center[0]);
    setSuggestions([]); // Clear the suggestions after selecting one
  };

  const [images, setImages] = useState([]);

  const onDrop = useCallback((acceptedFiles) => {
    setImages((prevImages) => [...prevImages, ...acceptedFiles]);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: "image/*",
    multiple: true,
  });

  const handleFileInputChange = (event) => {
    const files = event.target.files;
    const newImages = Array.from(files);
    setImages((prevImages) => [...prevImages, ...newImages]);
  };

  const handleLocationClick = () => {
    console.log("Hi from Fayaz");
    if (locationSearched) {
      // The user has performed a location search, so handleLocationClick will not be executed
      return;
    }

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const lat = position.coords.latitude;
          const lng = position.coords.longitude;
          mixpanel.track("SCHEDULE_SERVICE", {
            page: window.location.pathname,
            latitude: lat,
            longitude: lng,
          });
          updateLocation(lat, lng);
        },
        (error) => {
          console.log("Error getting location:", error);
        }
      );
    } else {
      console.log("Geolocation is not supported by this browser.");
      mixpanel.track("SCHEDULE_SERVICE", {
        page: window.location.pathname,
      });
    }
  };

  let carno = true;

  return (
    <motion.div variants={variantsMain} initial="hidden" animate="visible">
      {/* <form
                className=' h-full'
                action='#!'
                method='post'
              >
                 */}
      <div
        className="bg-white px-4 py-4 flex flex-col justify-center rounded-lg max-w-screen shadow-lg mb-[10%]"
        style={{ marginTop: 0 }}
      >
        {mechanicSelected ? (
          <>
            <motion.div
              variants={variants}
              className="w-full"
              initial="hidden"
              animate="visible"
            >
              <p className="flex font-bold text-xl md:text-2xl lg:text-3xl">
                Schedule a Service
              </p>
              <form
                className="flex  flex-col md:flex-col lg:flex-row justify-between p-2 w-full"
                action="#!"
                method="post"
              >


                <VehicleSelector   ref={vehicleRef} carno={true} className="w-[100%]" />
                
                
                <div>
                 <button
                    type="button"
                    className="text-base md:text-lg lg:text-xl font-sans hover:scale-2 bg-green-500 text-white font-semibold rounded-lg px-3 py-3 mt-3 cursor-pointer whitespace-nowrap"
                   onClick={handleClickSchedule}
                >
                   Schedule a Service
                </button>
              </div>
              </form>
            </motion.div>
          </>
        ) : (
          <></>
        )}
        {cleanerSelected ? (
          <>
            <motion.div variants={variants} initial="hidden" animate="visible">
              <form className=" h-full " action="#!" method="post">
                <div className="inputBox1-navbar mb-5">
                  <input type="text" required="required" />
                  <span className="user-navbar">Location</span>
                </div>
                <div className="inputBox2-navbar mb-5">
                  <input type="text" required="required" />
                  <span>Vehicle Model</span>
                </div>
                <div className="inputBox3-navbar mb-5">
                  <input type="text" required="required" />
                  <span className="user-navbar">Vehicle Number</span>
                </div>
                <div className="inputBox4-navbar mb-5">
                  <input type="text" required="required" />
                  <span>Issue</span>
                </div>

                <Link to="/service">
                  <div className="w-[250px] text-lg  text-center hover:scale-2 bg-green-500 text-white font-bold rounded-lg px-3 py-2 mt-1 mb-3 cursor-pointer">
                    Hire a Cleaner
                  </div>
                </Link>
              </form>
            </motion.div>
          </>
        ) : (
          <></>
        )}
        {driverSelected ? (
          <>
            <motion.div variants={variants} initial="hidden" animate="visible">
              <form className=" h-full" action="#!" method="post">
                <div className="inputBox1-navbar mb-5">
                  <input type="text" required="required" />
                  <span className="user-navbar">Location</span>
                </div>
                <div className="inputBox2-navbar mb-5">
                  <input type="text" required="required" />
                  <span>Vehicle Model</span>
                </div>
                <div className="inputBox3-navbar mb-5">
                  <input type="text" required="required" />
                  <span className="user-navbar">Vehicle Number</span>
                </div>
                <div className="inputBox4-navbar mb-5">
                  <input type="text" required="required" />
                  <span>Issue</span>
                </div>

                <Link to="/service">
                  <div className="w-[250px] text-lg  text-center hover:scale-2 bg-green-500 text-white font-bold rounded-lg px-3 py-2 mt-1 mb-3 cursor-pointer">
                    Hire a Driver
                  </div>
                </Link>
              </form>
            </motion.div>
          </>
        ) : (
          <></>
        )}
      </div>
        
      {/* </form> */}
    </motion.div>
  );
};

export default Form;
