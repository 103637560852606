import React, { useEffect, useState } from "react";
import TrackPageNavbar from "./TrackPageNavbar";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import SERVER_URL from "../constants/constant";
import FilterListOutlinedIcon from '@mui/icons-material/FilterListOutlined';

const OrderHistory = () => {
  // const dummyData = [
  //   {
  //     mechanicName: "Mechanic Name",
  //     amount: "2,500",
  //     orderId: "12345671",
  //     paymentMethod: "UPI",
  //     serviceDetails: "Basic Service, Front Break Pad (2 service) ",
  //     status: "On Going",
  //     estimationTime: "17 May 2024 (10:30)",
  //     address: "1st Cross road, BAD COLONY, Bangalore, 600251",
  //     mechanicImage:
  //       "https://s3-alpha-sig.figma.com/img/ed7a/fb95/d4bab49345e40720db7092a270b0d65d?Expires=1707696000&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=FZkDZOWFFwEFCA58GkgMt8~o7dr6zoGulQZpPSpzrwKxJ7U0nmjbFynFd0VXzGtZg8N5FS5y25Tlst-i25ijXT0wwJyiXbvTLpDdfGhlxES9Na30zUyydi2GGTeYn8~mpESWB1TLF6in3VdBjkLzO3h39YFsnkI1h1ZWZ8~6EN7NvbSYd8krEZQkOiKREhwAdJTbYXM9p8rB4wrGUNJ6R8CzTUlytgc1OQvAr5z1sM4ApiFg2in7txGMUctxNrb4qLOeAdhAfGpRH978HTmVTyBqpKU6pIgytdz39sfWQ-QkvETitpbRABvLYaCKyPwEOWz6ZFiQXg-UbPQmSeAcYw__",
  //   },
  //   {
  //     mechanicName: "Mechanic Name",
  //     amount: "3,000",
  //     orderId: "78901234",
  //     paymentMethod: "Credit Card",
  //     serviceDetails: "Basic Service, Front Break Pad (2 service)",
  //     status: "Completed",
  //     estimationTime: "20 May 2024 (12:00)",
  //     address: "1st Cross road, BAD COLONY, Bangalore, 600251",
  //     mechanicImage:
  //       "https://s3-alpha-sig.figma.com/img/ed7a/fb95/d4bab49345e40720db7092a270b0d65d?Expires=1707696000&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=FZkDZOWFFwEFCA58GkgMt8~o7dr6zoGulQZpPSpzrwKxJ7U0nmjbFynFd0VXzGtZg8N5FS5y25Tlst-i25ijXT0wwJyiXbvTLpDdfGhlxES9Na30zUyydi2GGTeYn8~mpESWB1TLF6in3VdBjkLzO3h39YFsnkI1h1ZWZ8~6EN7NvbSYd8krEZQkOiKREhwAdJTbYXM9p8rB4wrGUNJ6R8CzTUlytgc1OQvAr5z1sM4ApiFg2in7txGMUctxNrb4qLOeAdhAfGpRH978HTmVTyBqpKU6pIgytdz39sfWQ-QkvETitpbRABvLYaCKyPwEOWz6ZFiQXg-UbPQmSeAcYw__",
  //   },
  //   {
  //     mechanicName: "Mechanic Name",
  //     amount: "3,000",
  //     orderId: "78901234",
  //     paymentMethod: "Credit Card",
  //     serviceDetails: "Basic Service, Front Break Pad (2 service)",
  //     status: "Completed",
  //     estimationTime: "20 May 2024 (12:00)",
  //     address: "1st Cross road, BAD COLONY, Bangalore, 600251",
  //     mechanicImage:
  //       "https://s3-alpha-sig.figma.com/img/ed7a/fb95/d4bab49345e40720db7092a270b0d65d?Expires=1707696000&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=FZkDZOWFFwEFCA58GkgMt8~o7dr6zoGulQZpPSpzrwKxJ7U0nmjbFynFd0VXzGtZg8N5FS5y25Tlst-i25ijXT0wwJyiXbvTLpDdfGhlxES9Na30zUyydi2GGTeYn8~mpESWB1TLF6in3VdBjkLzO3h39YFsnkI1h1ZWZ8~6EN7NvbSYd8krEZQkOiKREhwAdJTbYXM9p8rB4wrGUNJ6R8CzTUlytgc1OQvAr5z1sM4ApiFg2in7txGMUctxNrb4qLOeAdhAfGpRH978HTmVTyBqpKU6pIgytdz39sfWQ-QkvETitpbRABvLYaCKyPwEOWz6ZFiQXg-UbPQmSeAcYw__",
  //   },
  // ];

  const [orders, setOrders] = useState([]);
  const [showPopup, setShowPopup] = useState(false); 
  const navigate = useNavigate();
  const [isFilterDropdownOpen, setFilterDropdownOpen] = useState(false);

  const data = {
    userId: 
"66d08cf974798f2934fcaeae",
// "66d08cf974798f2934fcaebe",
  };

  const handleOrder = (id) => {
    console.log(`Clicked on service with ID: ${id}`);
    navigate("/profile/order-history/order-details", {
      state: { orderId: id },
    });
  };
  
  const token = localStorage.getItem('TOKEN');
  const handleClosePopup = () => {
    setShowPopup(false);
  };

  useEffect(() => {
    axios
      .post(
        `${SERVER_URL}/user/getorderhistory/?status&page=1&limit=30`,
        data,
        {
          withCredentials: true,
          headers: {
            Authorization: `Bearer ${token}`,
          },

        }
      )
      .then((response) => {
        console.log(response);
        const orders = response.data.orders;
        if (orders) {
             const ordersAssigned = orders.mechanicTickets.filter(
            (order) =>
              order.status === "accepted" ||
              order.status === "pending" ||
              order.status === "completed"
          );


          if (ordersAssigned?.length === 0) {
            setShowPopup(true); // Show popup if no orders are found
          } else {
            console.log(ordersAssigned);
            setOrders(ordersAssigned);
          }
        
  
        } else {
          console.error("No services found in the response.");
        }
        
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);









const Popup = () =>{
  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
    <div className="relative bg-white p-6 rounded-lg shadow-lg w-full max-w-md">
      
      {/* Close button in the top-left corner */}
      <button
        onClick={handleClosePopup}
        className="absolute top-4 left-4 bg-red-500 text-white px-2 py-1 rounded-full hover:bg-red-600 transition"
      >
        X
      </button>

      {/* Heading in the center */}
      <h2 className="text-2xl font-semibold mb-4 text-center">Order History</h2>

      {/* Image placeholder and text */}
      <div className="flex flex-col items-center">
        <img src="" alt="No Orders" className="mb-4" />

        <p className="text-xl text-black mb-2">No order found</p>
        <p className="mb-4 text-center">It seems like you haven't scheduled any service yet.</p>

        {/* Button */}
        <Link
          to="/ServicePage"
          className="bg-green-500 w-full py-3 text-center text-lg text-white rounded-md hover:scale-110 transform transition duration-300 ease-in-out font-semibold"
        >
          Explore Service Now
        </Link>
      </div>
    </div>
  </div>

  );
};





return(
  
    <div>
      {/* Navbar */}
      <TrackPageNavbar showOnlyNav={false} />

      {/* Conditionally render popup if showPopup is true */}
      {showPopup && <Popup />} 

      {/* Breadcrumb navigation */}
      <div className="px-4 sm:px-6 md:px-8 lg:px-14 py-3 shadow-sm flex flex-col lg:flex-row gap-1 lg:gap-0 justify-between lg:items-center">
        <p className="text-sm text-gray-500 font-bold">
          <a href="/" className="hover:text-black hover:cursor-pointer">Profile</a> 
          <span className="text-black"> / </span>
          <span className="text-black">Order History</span>
        </p>
      </div>

      {/* Order History Header */}
      <div className="mt-6 px-4 sm:px-6 md:px-14">
        <div className="my-4" style={{ fontSize: "28px", fontWeight: "700px", fontFamily: "Poppins" }}>
          <h1>Order History</h1>
        </div>

        {/* Mapping over orders */}
        {orders
          ? orders.map((order, index) => (
              <div
                key={index}
                className="border border-black border-opacity-20 p-3 md:p-6 mb-3"
                style={{ width: "100%", borderRadius: "5px" }}
              >
                {/* Order details */}
                <div className="flex flex-col sm:flex-row sm:items-center">
                  {/* Mechanic Image */}
                  <div className="flex-shrink-0 mb-4 sm:mb-0">
                    <img alt="" src="" className="w-12 h-12 rounded-full overflow-hidden" />
                  </div>
                  
                  {/* Mechanic Name and Order Amount */}
                  <div className="flex-grow ml-0 sm:ml-4">
                    <div className="flex justify-between">
                      <span className="font-bold text-black">Mechanic Name: XYZ</span>
                      <span className="font-bold text-black" style={{ fontSize: "20px" }}>2500</span>
                    </div>
                    
                    {/* Order ID and Payment Method */}
                    <div className="flex justify-between">
                      <p style={{ color: "#515151" }}>Order ID: {order._id}</p>
                      <p style={{ color: "#515151" }}>UPI</p>
                    </div>
                  </div>
                </div>

                {/* Dashed line separator */}
                <hr className="border-b border-dashed border-black border-opacity-20 my-4" />

                {/* Order description, status, and other details */}
                <div className="flex flex-col ml-0 sm:ml-16">
                  {/* Service Details and Order Status */}
                  <div className="flex flex-row justify-between">
                    <span className="font-bold text-black" style={{ fontSize: "18px" }}>
                      Service:{" "}
                      <span style={{ color: "#515151", fontWeight: "lighter", fontSize: "16px" }}>
                        {order.description}
                      </span>
                    </span>
                    
                    {/* Order status or star rating */}
                    {order.status !== "completed" ? (
                      <span style={{ fontFamily: "Poppins", whiteSpace: "nowrap" }} className="font-bold text-red-500 mt-1">
                        {order.status}
                      </span>
                    ) : (
                      <div className="flex items-center space-x-2">
                        {/* Star Rating */}
                        {Array.from({ length: 5 }).map((_, i) => (
                          <span
                            key={i}
                            className={`text-yellow-500 ${i < 4 ? "fill-star" : "empty-star"} md:text-2xl lg:text-3xl xl:text-4xl`}
                          >
                            &#9733;
                          </span>
                        ))}
                      </div>
                    )}
                  </div>
                  
                  {/* Estimation Time */}
                  <div className="font-bold text-black" style={{ fontSize: "18px" }}>
                    Estimation Time:{" "}
                    <span style={{ color: "#515151", fontWeight: "lighter", fontSize: "16px" }}>
                      "17 Oct 2024 (10:30)"
                    </span>
                  </div>

                  {/* Address and Help/Track Order */}
                  <div className="flex flex-col sm:flex-row justify-between items-center">
                    <span className="font-bold text-black" style={{ fontSize: "18px" }}>
                      Address:{" "}
                      <span style={{ color: "#515151", fontWeight: "lighter", fontSize: "16px" }}>
                        "1st Cross Road, BAD COLONY, Bangalore, 600251"
                      </span>
                    </span>
                    <span style={{ color: "#22C55D", fontSize: "16px" }} className="font-bold mr-2">Help</span>

                    {/* Button for track order or order details */}
                    {order.status !== "completed" ? (
                      <button
                        className="w-200 h-40 rounded-5 bg-white text-green-500 border-2 border-green-500 transition duration-300 hover:bg-green-500 hover:text-white"
                        style={{ width: "200px", height: "40px", borderRadius: "5px" }}
                      >
                        <Link to="/profile/order-history/order-details/track">Track order</Link>
                      </button>
                    ) : (
                      <button
                        className="w-200 h-40 rounded-5 bg-white text-green-500 border-2 border-green-500 transition duration-300 hover:bg-green-500 hover:text-white"
                        style={{ width: "200px", height: "40px", borderRadius: "5px" }}
                        onClick={() => handleOrder(order._id)}
                      >
                        Order Details
                      </button>
                    )}
                  </div>
                </div>
              </div>
            ))
          : null}
      </div>
    </div>
  );


};

export default OrderHistory;