import React, { useEffect, useState } from "react";
import { json, Link } from "react-router-dom";
import star from "../assets/ServiceDetails/star.png";
import img from "../assets/service.png";
import rightVector from "../../src/assets/ServiceDetails/rightVector.png";
import SERVER_URL from "../constants/constant";
import axios from "axios";
import toast from "react-hot-toast";
import mixpanel from "../config/mixpanel";
import { useNavigate } from "react-router-dom";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useReducer } from "react";
import { cartReducer } from "../context/CartReducer";
import { useContext } from "react";
import { CartContext } from "../context/CartContext";

const renderStars = (rating) => {
  const fullStars = Math.min(Math.floor(rating), 5);
  const halfStar = rating % 1 !== 0 && fullStars < 5;
  const emptyStars = 5 - fullStars - (halfStar ? 1 : 0);

  const starArray = Array(fullStars).fill("★");
  if (halfStar) starArray.push("½");
  const emptyStarArray = Array(emptyStars).fill("☆");

  return (
    <div className="flex items-center">
      {starArray.concat(emptyStarArray).map((star, index) => (
        <span key={index} className="text-yellow-500 text-2xl">
          {star}
        </span>
      ))}
    </div>
  );
};
// const renderStars = (rating) => {
//   // Ensure rating is within 0 to 5

//   const validRating = Math.max(0, Math.min(rating, 5));

//   // Calculate full, half, and empty stars
//   const fullStars = Math.floor(validRating);
//   const hasHalfStar = validRating % 1 !== 0 && fullStars < 5;
//   const emptyStars = 5 - fullStars - (hasHalfStar ? 1 : 0);

//   // Create star arrays
//   const starArray = Array(fullStars).fill("★");
//   if (hasHalfStar) starArray.push("½");
//   const emptyStarArray = Array(emptyStars).fill("☆");

//   // Concatenate full, half, and empty stars into one array
//   const allStars = starArray.concat(emptyStarArray);

//   return (
//     <div className="flex items-center">
//       {allStars.map((star, index) => (
//         <span key={index} className="text-yellow-500 text-2xl">
//           {star}
//         </span>
//       ))}
//     </div>
//   );
// };

const initialState = [];
const ServiceDetails = ({ service, model }) => {
  const navigate = useNavigate();

  // const { dispatch, addService } = useContext(CartContext);
  const { addToCart,cart } = useContext(CartContext);
  // const handlebuyNow=()=>{
  //   navigate("/", {
  //     state: { serviceId: id},
  //   });
  // }

  const [isInCart, setIsInCart] = useState(false);
  const roundedRating = service.avgRating.toFixed(1);

  const [cartAdded, setCartAdded] = useState(0);
  const displayTextOptions = {
    0: "Add to",
    1: "Already in the",
    2: "Added to",
  };

  // new chaange add to cart function is defined in cart context 
  const handleAddCart = (service) => {
    console.log("service that we are sending to coontext is this",service);
    addToCart(service); 
    setIsInCart(true);
    };


  // const handleAddCart = (service) => {
  //   // const modelId = JSON.parse(localStorage.getItem("vehicle-selected")).model._id;
    
    
  //   const modelId = model.carModel;
  //   const token = localStorage.getItem("TOKEN");
  //   dispatch({ type: "ADD_TO_CART", payload: service });
  //   axios
  //     .post(
  //       `${SERVER_URL}/user/cart/add`,
  //       {
  //         serviceId: service._id,
  //         modelId: modelId,
  //       },
  //       {
  //         withCredentials: true,
  //         headers: {
  //           Authorization: `Bearer ${token}`,
  //         },
  //       }
  //     )
  //     .then((response) => {
       
        
  //       const cartItems = response.data.userCart.cartItems;
  //       console.log("cart items insdie the service details page is", cartItems);
       

  //       if (
  //         response.data.success === true &&
  //         response.data.message === "successfully added to cart"
  //       ) {
  //         toast.success(response.data.message);
  //         setCartAdded(2);
  //         mixpanel.track("Added_to_Cart", { serviceId: service._id });
  //       } else if (response.data.success === true) {
  //         toast(response.data.message);
  //         setCartAdded(1);
  //       } else {
  //         toast.error(response.data.message);
  //       }
  //     })
  //     .catch((error) => {
  //       console.error("API Error:", error);
  //     });
  // };

  const handleBuyNowClick = (id) => {
    mixpanel.track("Buy Now Clicked");

    navigate("/serviceinfo");
  };

  return (
    <div className="bg-white p-4 shadow-md rounded-md mb-4 flex flex-col gap-5 lg:flex-row mt-8 w-full">
      <div className="w-full lg:w-3/5 md:w-full md:min-h-[590px] flex flex-col justify-start p-2">
        <img
          src={img}
          alt=""
          className="w-full h-auto object-cover rounded-md mb-4"
        />
        <div className="h-48 overflow-y-auto scrollbar-thin md:h-auto md:max-h-full">
          <h3 className="text-lg font-semibold mb-2">
            Reviews{" "}
            <span className="bg-black text-white px-5  rounded-sm">
              {service.reviews.length}
            </span>
          </h3>
          <ul className="list-none pl-4">
            {service.reviews.map((eachReview, index) => (
              <li key={index} className="mb-4">
                <div className="flex justify-between items-center mb-2">
                  <div className="flex items-center">
                    <p className="text-gray-700 font-semibold ">
                      {eachReview.userName}
                    </p>
                  </div>
                  {/* {renderStars(eachReview.rating)} */}
                </div>
                <p className="text-gray-500">{eachReview.text}</p>
              </li>
            ))}
          </ul>
        </div>
      </div>
      <div className="w-full lg:w-2/5 p-2 md:w-full md:min-h-[590px] flex flex-col justify-start text-black">
        <h2 className="text-2xl font-semibold mb-2">{service.name}</h2>
        <div className="items-center">
          <div className="flex items-center rounded-sm">
            <div className="flex items-center border rounded  bg-green-500 transform hover:scale-105 transition-all duration-300 text-white mr-2 p-1">
              {roundedRating}{" "}
              <img
                src={star}
                className="flex items-center p-1 justify-center"
                alt="star"
              />
            </div>
          </div>
        </div>
        {/* <div className="mt-2 mb-3">
          <p className="text-black">₹ {service.price}</p>
        </div> */}

        <div className="mt-2 mb-3">
          <p className="text-black">
            <p>
              M.R.P{" "}
              <span className="text-black line-through text-sm">{` ₹${model.price}`}</span>
            </p>
            {/* ₹ {service.price} */}
            <span className="text-red-600">{`-${model.discount}%`}</span>{" "}
            {`₹${model.newPrice}`}{" "}
            <span
              className="w-24 h-6 "
              style={{
                background:
                  "linear-gradient(to right, rgba(167, 148, 50, 1), rgba(194, 180, 83, 1), rgba(217, 207, 110, 1), rgba(234, 226, 129, 1), rgba(244, 237, 141, 1), rgba(247, 241, 145, 1), rgba(241, 234, 138, 1), rgba(225, 215, 119, 1), rgba(199, 185, 88, 1), rgba(167, 148, 50, 1))",
              }}
            >
              {" "}
              Save {`₹${(model.price - model.newPrice).toFixed(2)}`}
            </span>
          </p>
        </div>
        <div className="flex flex-col items-center mt-4 md:flex-row md:items-center md:mt-0 gap-2">
          {/* <button
            className="border border-green-500 text-green-500 transform hover:scale-105 transition-all duration-300 hover:bg-green-500 rounded-sm text-sm hover:text-white px-4 py-1 mr-2 font-bold"
            onClick={() => handleAddCart(service)}
            // onClick={() => handleAddCart(service._id)}
          >
            {cartAdded === 2 &&
              displayTextOptions[cartAdded] === "Added to" && (
                <span className="mx-auto mr-2" style={{ color: "#22C55D" }}>
                  <FontAwesomeIcon icon={faCheck} />
                </span>
              )}
            {displayTextOptions[cartAdded]} cart
          </button> */}


          {/* newly adeede button  */}
          <button
            className={`border border-green-500 text-green-500 transform hover:scale-105 transition-all duration-300 rounded-sm text-sm px-4 py-1 mr-2 font-bold ${
              isInCart ? "cursor-not-allowed bg-gray-300 text-gray-500" : "hover:bg-green-500 hover:text-white"
            }`}
            onClick={() => handleAddCart(service)}
            // disabled={isInCart} // Disable button if item is already in cart
          >
            {isInCart ? "Already in Cart" : "Add to Cart"}
          </button>

          <button
            className="bg-green-500 text-white px-6 py-1 rounded-sm mr-2 transform hover:scale-105 transition-all duration-300 font-bold text-sm"
            onClick={handleBuyNowClick}
          >
            Buy Now
          </button>
        </div>
        <div className="mt-4 border-b border-gray-300"></div>
        <div className="mt-4">
          <h3 className="text-lg font-semibold mb-2">Description</h3>
          <p className="text-gray-700">{service.description}</p>
        </div>
        <div className="mt-4 border-b border-gray-300"></div>
        <div className="mt-4">
          <h3 className="text-lg font-semibold mb-2">Service Included</h3>
          <ul className="list-none lg:pl-4">
            {service.serviceIncluded
              ? service.serviceIncluded.map((includedServicesData, index) => (
                  <li
                    // change by me
                    key={includedServicesData._id}
                    className="flex items-start mb-4"
                  >
                    <img
                      src={rightVector}
                      alt="Bullet Point"
                      className="mr-2 w-4 h-4"
                    />
                    <div>
                      <h4 className="text-gray-700 font-semibold mb-2">
                        {includedServicesData.subName}
                      </h4>
                      <p className="text-gray-500">
                        {includedServicesData.subDescription}
                      </p>
                    </div>
                  </li>
                ))
              : null}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default ServiceDetails;
