
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import RangeSlider from "@mui/material/Slider"
import { FaCartPlus, FaShoppingCart } from "react-icons/fa";
import { FaStar } from "react-icons/fa6";
import img from "../assets/service.png";
import left from "../assets/ServiceDetails/left.png";
import right from "../assets/ServiceDetails/right.png";
import TrackPageNavbar from "../components/TrackPageNavbar";
import { useState, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import axios from "axios";
import SERVER_URL from "../constants/constant";
import { useRef } from "react";
import FmdGoodOutlinedIcon from "@mui/icons-material/FmdGoodOutlined";
import ExpandMoreOutlinedIcon from "@mui/icons-material/ExpandMoreOutlined";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import FilterListOutlinedIcon from "@mui/icons-material/FilterListOutlined";
import MyLocationOutlinedIcon from "@mui/icons-material/MyLocationOutlined";
import HomeIcon from "@mui/icons-material/Home";
import mixpanel from "../config/mixpanel";
import { debounce } from "lodash";
import { IconButton, Tooltip, Typography } from "@mui/material";
import { useLocation } from "react-router-dom";
import  { useContext } from 'react';
import { CartContext } from "../context/CartContext";
const ServicePageNew = () => {
  const [servicesByCategory,
    setServicesByCategory] = useState([]);
  const [carouselSlideIndices, setCarouselSlideIndices] = useState({});
  const [scaledIndex, setScaledIndex] = useState({});
  const [isLocationDropdownOpen, setLocationDropdownOpen] = useState(false);
  const [isFilterDropdownOpen, setFilterDropdownOpen] = useState(false);
  const [isSortDropdownOpen, setSortDropdownOpen] = useState(false);
  const navigate = useNavigate();
  const slidersRef = useRef([]);
  const arr = [1, 2];
  const [filteredServices, setFilteredServices] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [modelId, setModelId] = useState("");

  const dropdownRef = useRef(null);

  const filterDropdownRef = useRef(null);
  const sortDropdownRef = useRef(null);
  const MIN = 0;
  const MAX = 20000;
  const [priceRange, setPriceRange] = useState([MIN, MAX])
  const [sortOption, setSortOption] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();
 
  const typeFilter = searchParams.get("type")
  console.log(typeFilter);



  // const modelId=JSON.parse(localStorage.getItem("vehicle-selected"))
  // console.log("model id that we have selected is this and on  the basis this i fill get caterogry  ",modelId.model._id);
  const {addToCart} = useContext(CartContext);
 
 
  const cartPlusIcon = async (e, service) => {
    e.stopPropagation();
  
    console.log("cartplus button is clicked");
    console.log(service);
  
    try {
      // Wait for the addToCart function to complete
      await addToCart(service);
      
      // After addToCart completes, navigate to the next page
      navigate("/cartinfo");
    } catch (error) {
      console.error("Failed to add to cart", error);
      // Handle error (you could show an error message to the user)
    }
  };
  
  



  const handleClickOutside = (event) => {
    if (
      filterDropdownRef.current &&
      !filterDropdownRef.current.contains(event.target)
    ) {
      setFilterDropdownOpen(false);
    }
    if (
      sortDropdownRef.current &&
      !sortDropdownRef.current.contains(event.target)
    ) {
      setSortDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const handleSearchChange = (e) => {
    const searchText = e.target.value.toLowerCase();

    setSearchText(searchText); // Update the search text state

    setLoading(true);

    // Filter services based on the search text
    const filtered = Object.values(servicesByCategory)
      .flat()
      .filter((service) => {
        return service.name.toLowerCase().includes(searchText);
      });

    setFilteredServices(filtered);
    setLoading(false);

    if (searchText.trim() !== "") {
      setLocationDropdownOpen(true);
    } else {
      setLocationDropdownOpen(false);
    }
  };

  const settings = (index) => ({
    dots: true,
    infinite: true,
    speed: 800,
    slidesToShow: 3,
    slidesToScroll: 1,
    beforeChange: (current, next) => updateSlideIndex(index, next),
    centerMode: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  });

  const updateSlideIndex = (index, next) => {
    setCarouselSlideIndices((prevIndices) => ({
      ...prevIndices,
      [index]: next,
    }));
    setScaledIndex((prevIndices) => ({
      ...prevIndices,
      [index]: next,
    }));
  };

  useEffect(() => {
    Object.keys(servicesByCategory).forEach((category, index) => {
      setScaledIndex((prevIndices) => ({ ...prevIndices, [index]: 0 }));
      setCarouselSlideIndices((prevIndices) => ({
        ...prevIndices,
        [index]: 0,
      }));
    });
  }, [servicesByCategory]);

  useEffect(() => {
    Object.entries(scaledIndex).forEach(([index, scaled]) => {
      if (scaled !== undefined) {
        const slider = slidersRef.current[index];
        slider && slider.slickGoTo(scaled, true);
      }
    });
  }, [scaledIndex]);


  useEffect(() => {
    const vechileData = (localStorage.getItem("vehicle-selected"));

    if (vechileData) {
      const parsedData = JSON.parse(vechileData);
      const id = parsedData.modelId;
      setModelId(id);
    }



  }, [])




  useEffect(() => {
    if (modelId) {
      console.log("service page is ");
      mixpanel.track("Services Page View");
      axios
        .get(`${SERVER_URL}/service/getAll-by-category?modelId=${modelId}`)

        .then((response) => {
          console.log("respsone is API", response);
          const { services } = response.data;
          console.log("Fetched services:", services);
          console.log("service fected is ");

          if (services && services.length > 0) {
            // const serviceByCategory =  services.reduce((acc, service) => {
            //   if (!acc[service.category]) {
            //     acc[service.category] = [];
            //   }
            //   acc[service.category].push(service);
            //   return acc;
            const serviceByCategory = services.reduce((acc, service) => {
              if (!acc[service.category]) {
                acc[service.category] = {
                  description: service.description,
                  subServices: [],
                };
              }


              acc[service.category].subServices.push(...service.subServices);

              return acc;
            }, {});

            setServicesByCategory(serviceByCategory);
            //setFilteredServices(serviceByCategory);
            console.log("Services grouped by category:", serviceByCategory);
          } else {
            console.error("No services found in the response.");
          }
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    }
  }, [modelId]);


  const handleService = (id) => {
    console.log(`Clicked on service with ID: ${id}`);
    mixpanel.track("Service Clicked", { serviceId: id });
    navigate("/servicepage/1", { state: { serviceId: id } });
  };

  const handleServiceAll = (name) => {
    console.log(`Clicked on service with ID: ${name}`);
    mixpanel.track("View All Services", { categoryName: name });
    navigate(`/servicepage/${name}`, { state: { categoryName: name } });
  };

  function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <img
        src={right}
        alt=""
        className={className}
        style={{ ...style, display: "block", width: "25px", height: "25px" }}
        onClick={onClick}
      />
    );
  }

  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <img
        src={left}
        alt=""
        className={className}
        style={{ ...style, display: "block", width: "25px", height: "25px" }}
        onClick={onClick}
      />
    );
  }

  const toggleLocationDropdown = () => {
    setLocationDropdownOpen(!isLocationDropdownOpen);
    setFilterDropdownOpen(false);
    setSortDropdownOpen(false);
  };

  const toggleFilterDropdown = () => {
    setFilterDropdownOpen(!isFilterDropdownOpen);
    setSortDropdownOpen(false);
    setLocationDropdownOpen(false);
  };

  const toggleSortDropdown = () => {
    setSortDropdownOpen(!isSortDropdownOpen);
    setFilterDropdownOpen(false);
    setLocationDropdownOpen(false);
  };

  const handleSort = (option) => {
    setSortOption(option);

    // Sort services based on the selected option
    console.log(servicesByCategory)
    let sortedServices = [...filteredServices];
    /*switch (option) {
      case "lowToHigh":
        Object.entries(servicesByCategory).map(([category,service],index)=>
        service.sort((a, b) => a.price - b.price));
        break;
      case "highToLow":
        sortedServices.sort((a, b) => b.price - a.price);
        break;
      case "newest":
        sortedServices.sort(
          (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
        );

        break;
      case "popular":
        sortedServices.sort((a, b) => b.avgRating - a.avgRating);

        break;
      default:
        break;
    }*/
    switch (option) {
      case "lowToHigh":
        Object.entries(servicesByCategory).map(([category, service], index) =>
          service.sort((a, b) => a.price - b.price));
        break;
      case "highToLow":
        Object.entries(servicesByCategory).map(([category, service], index) =>
          service.sort((a, b) => b.price - a.price));
        break;
      case "newest":
        Object.entries(servicesByCategory).map(([category, service], index) =>
          service.sort(
            (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
          ));

        break;
      case "popular":
        Object.entries(servicesByCategory).map(([category, service], index) =>
          service.sort((a, b) => b.avgRating - a.avgRating));

        break;
      default:
        break;
    }

    setFilteredServices(sortedServices);
  };

  const updateDebounce = debounce((text) => {
    console.log(`text ${text}`);
    let url = `${SERVER_URL}/services/filterServices?sortBy=price&minPrice=${text[0]}&maxPrice=${text[1]}`;
    axios
      .get(url, {
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        const { services } = response.data;

        if (services && services.length > 0) {
          const serviceByCategory = services.reduce((acc, service) => {
            if (!acc[service.category]) {
              acc[service.category] = [];
            }
            acc[service.category].push(service);
            return acc;
          }, {});

          setServicesByCategory(serviceByCategory);
          console.log(serviceByCategory);
        } else {
          console.log("No services found in the response.");
          setServicesByCategory([]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  });

  const handlePriceChange = (e) => {
    const price = e.target.value;
    console.log(price)
    //console.log(newVal)
    setPriceRange(price);
    updateDebounce(price);
  };

  return (
    <div className="overflow-y-hidden">
      <TrackPageNavbar showOnlyNav={false} />
      <div className="px-4 sm:px-6 md:px-14 py-3 shadow-sm flex flex-col lg:flex-row gap-1 lg:gap-0 justify-between lg:items-center">
        <p className="text-sm text-gray-500 font-bold">
          <a href="/" class="hover:text-black hover:cursor-pointer">Home</a>

          <span className="text-black"> / </span>
          <span className="text-black">Service</span>
        </p>
        <div className="w-full lg:w-3/4 flex flex-col sm:flex-row gap-2 sm:gap-0 sm:items-center justify-between gap-x-8">
          <div className="w-full sm:w-3/5 flex items-center relative flex-col">
            <div className="flex items-center pl-1 sm:pl-2 gap-x-2 p-2 relative border border-gray-300 rounded-md w-full">
              <SearchOutlinedIcon
                style={{ width: "18px", height: "18px", color: "gray" }}
              />
              <input
                type="text"
                placeholder="Search Service"
                className="outline-none text-xs sm:text-base flex-1"
                onChange={handleSearchChange}
              />
            </div>
            {loading && (
              <p className="absolute top-full left-0 ml-2">Loading...</p>
            )}
            {isLocationDropdownOpen && filteredServices.length > 0 && (
              <div
                className={`absolute z-50 w-full left-0 mt-11 bg-white border rounded-md shadow-md  overflow-y-auto max-h-48`}
              >
                {filteredServices.map((service) => (
                  <div
                    key={service._id}
                    className="p-2 cursor-pointer hover:bg-gray-100 w-full"
                    onClick={() => (service._id)}
                  >
                    {service.name}
                  </div>
                ))}
              </div>
            )}
          </div>
          <div className="flex justify-between ">
            <div ref={filterDropdownRef} className="relative">
              <button
                onClick={toggleFilterDropdown}
                className="flex items-center gap-x-2 border-[1.5px] border-gray-400 outline-none p-1.5 px-3 rounded text-xs sm:text-base"
              >
                <FilterListOutlinedIcon className="cursor-pointer" />
                Filter
              </button>
              {isFilterDropdownOpen && (
                <div className="origin-top-right absolute -left-5 top-8 mt-2 w-60 z-50 rounded-md shadow-xl bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                  <div className="p-6 flex flex-col gap-y-5">
                    <div>
                      <p className="font-semibold">Service Type</p>
                      <div>
                        <p className="flex items-center gap-x-2 mb-2 hover:bg-gray-100 hover:rounded p-2 text-sm cursor-pointer">
                          <span className="w-[5px] h-[5px] rounded-[50%] bg-black"></span>
                          Regular Maintenance
                        </p>
                        <p className="flex items-center gap-x-2 mb-2 hover:bg-gray-100 hover:rounded p-2 text-sm cursor-pointer">
                          <span className="w-[5px] h-[5px] rounded-[50%] bg-black"></span>
                          Repair
                        </p>
                        <p className="flex items-center gap-x-2 hover:bg-gray-100 hover:rounded p-2 text-sm cursor-pointer">
                          <span className="w-[5px] h-[5px] rounded-[50%] bg-black"></span>
                          Diagnostic Services
                        </p>
                      </div>
                    </div>
                    <div>
                      <p className="font-medium mb-2">Price Range</p>
                      {/*<input
                        type="range"
                        className="w-full"
                        value={priceRange}
                        min={MIN}
                        max={MAX}
                        onChange={handlePriceChange}
                        />*/}
                      <RangeSlider
                        size="small"
                        value={priceRange}
                        min={MIN}
                        max={MAX}
                        onChange={handlePriceChange}
                        valueLabelDisplay="auto"

                      />
                      <div className="flex items-center justify-between -mt-2 text-sm">
                        <span>0</span>
                        <span>20k</span>
                      </div>
                      <div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div ref={sortDropdownRef} className="relative">
              <button
                onClick={toggleSortDropdown}
                className="flex items-center gap-x-2 border-[1.5px] border-gray-400 outline-none p-1.5 px-3 rounded text-xs sm:text-base ml-4"
              >
                Sort
                <ExpandMoreOutlinedIcon className="cursor-pointer" />
              </button>
              {isSortDropdownOpen && (
                <div className="origin-top-right absolute -left-7 top-8 mt-2 w-[150px] z-50 rounded-md shadow-xl bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                  <div className="px-4 py-6">
                    <p
                      onClick={() => handleSort("lowToHigh")}
                      className="flex items-center gap-x-2 mb-2 hover:bg-gray-100 hover:rounded p-2 text-sm cursor-pointer">
                      <span className="w-[5px] h-[5px] rounded-[50%] bg-black"></span>
                      Low to High
                    </p>
                    <p
                      onClick={() => handleSort("highToLow")}
                      className="flex items-center gap-x-2 mb-2 hover:bg-gray-100 hover:rounded p-2 text-sm cursor-pointer">
                      <span className="w-[5px] h-[5px] rounded-[50%] bg-black"></span>
                      High to Low
                    </p>
                    <p
                      onClick={() => handleSort("newest")}
                      className="flex items-center gap-x-2 mb-2 hover:bg-gray-100 hover:rounded p-2 text-sm cursor-pointer">
                      <span className="w-[5px] h-[5px] rounded-[50%] bg-black"></span>
                      Newest
                    </p>
                    <p
                      onClick={() => handleSort("popular")}
                      className="flex items-center gap-x-2 mb-2 hover:bg-gray-100 hover:rounded p-2 text-sm cursor-pointer">
                      <span className="w-[5px] h-[5px] rounded-[50%] bg-black"></span>
                      Popular
                    </p>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {/* {Object.entries(servicesByCategory).map(([category ,{ description, subServices }], index) => (
        <div
          key={index}
          className={`px-8 sm:px-14 py-3 ${index === 0 ? "mt-4" : "mt-14"} ${
            index === Object.entries(servicesByCategory).length - 1
              ? "mb-20"
              : "mb-0"
          }`}
        >
          <div className="flex justify-between items-center mb-5">
            <p
              className={`text-xl font-bold max-w-max relative after:h-[2px] after:bg-green-400 after:mt-0.5 after:w-[40px] after:flex after:absolute after:right-0`}
            >
              {category}
            </p>
            <div
              className="text-xs font-bold text-green-400 cursor-pointer "
              onClick={() => handleServiceAll(category)}
            >
              View All
            </div>
          </div>
          <div>
            <Slider {...settings(index)}>
              {/* {services.map((service, serviceIndex) => (
                <div
                  className={
                    serviceIndex === carouselSlideIndices[index]
                      ? "transform scale-125 sm:scale-100 my-10 md:my-0"
                      : "transform scale-0 sm:scale-[0.75]"
                  }
                  key={serviceIndex}
                >
                  <div
                    className="relative rounded-md cursor-pointer "
                    onClick={() => handleService(service._id)}
                  >
                    <img src={img} alt="" className="rounded-md cursor-pointer" />
                    <div className="absolute bottom-0 p-1.5 sm:p-3.5 w-full">
                      <div className="flex justify-between items-center">
                        <p className="text-[9px] md:text-base lg:text-lg xl:text-2xl text-white font-bold">
                          {service.name}
                        </p>
                        <p className="text-white text-[8px] md:text-xs font-semibold py-0 sm:py-0.5 px-1 rounded bg-green-500 flex items-center gap-x-1">
                          {service.avgRating}
                          <FaStar color="white" />
                        </p>
                      </div>
                      <p className="hidden sm:block text-white opacity-85 text-[8px] sm:text-xs ">
                        1000 Kms or 1 Month warranty | Every 5000 Kms or 3
                        Months
                      </p>
                      <div className="flex justify-between items-center">
                        <p className="text-[8px] sm:text-xs xl:text-base text-white font-bold">
                          Rs.{" "}
                          <span className="line-through text-gray-400 font-light">
                            {service.price}
                          </span>{" "}
                          {service.price}
                        </p>
                        <div className="flex justify-between items-center gap-x-5">
                          <Tooltip title="Add to Cart">
                          <IconButton>
                          <FaShoppingCart
                            color="white"
                            className="w-4 h-4 md:w-5 md:h-5"
                          />
                          </IconButton>
                          </Tooltip>
                          <Tooltip title="Buy Now" >
                          <IconButton >
                          <FaCartPlus
                            color="white"
                            className="w-4 h-4 md:w-5 md:h-5"
                          />
                           </IconButton>
                          </Tooltip>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))} */
        //           <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
        //           {subServices.map((subService) => (
        //             <div key={subService._id} className="bg-white p-4 shadow-md rounded-lg">
        //               <img
        //                 src={subService.photo}
        //                 alt={subService.name}
        //                 className="w-full h-40 object-cover rounded-t-lg"
        //               />
        //               <div className="mt-4">
        //                 <p className="text-lg font-bold">{subService.name}</p>
        //                 <p className="text-sm text-gray-600">{subService.description}</p>
        //                 <p className="text-sm text-gray-500">Warranty: {subService.warranty}</p>
        //                 <p className="text-sm text-gray-500">Duration: {subService.duration}</p>
        //               </div>
        //             </div>
        //           ))}
        //         </div>
        //         }

        //       </Slider>
        //     </div>
        //   </div>
        // ))} */ }
        Object.entries(servicesByCategory).map(([category, { description, subServices }], index) => (
          <div
            key={index}
            className={`px-8 sm:px-14 py-3 ${index === 0 ? "mt-4" : "mt-14"} ${index === Object.entries(servicesByCategory).length - 1
              ? "mb-20"
              : "mb-0"
              }`}
          >
            <div className="flex justify-between items-center mb-5">
              <p
                className={`text-xl font-bold max-w-max relative after:h-[2px] after:bg-green-400 after:mt-0.5 after:w-[40px] after:flex after:absolute after:right-0`}
              >
                {category}
              </p>
              <div
                className="text-xs font-bold text-green-400 cursor-pointer"
                onClick={() => handleServiceAll(category)}
              >
                View All
              </div>
            </div>
            <p className="text-sm text-gray-500 mb-4">{description}</p> {/* Render the category description */}


            <Slider {...settings(index)}>
              {subServices.map((subService, serviceIndex) => (
                <div
                  className={
                    serviceIndex === carouselSlideIndices[index]
                      ? "transform scale-125 sm:scale-100 my-10 md:my-0"
                      : "transform scale-0 sm:scale-[0.75]"
                  }
                  key={serviceIndex}
                >
                  <div
                    className="relative rounded-md cursor-pointer"
                    onClick={() => handleService(subService._id)}
                  >
                    <img src={img} alt="" className="rounded-md cursor-pointer" />
                    <div className="absolute bottom-0 p-1.5 sm:p-3.5 w-full">
                      <div className="flex justify-between items-center">
                        <p className="text-[9px] md:text-base lg:text-lg xl:text-2xl text-white font-bold">
                          {subService.name}

                        </p>
                        <p className="text-white text-[8px] md:text-xs font-semibold py-0 sm:py-0.5 px-1 rounded bg-green-500 flex items-center gap-x-1">
                          {subService.avgRating}
                          <FaStar color="white" />
                        </p>
                      </div>
                      <p className="hidden sm:block text-white opacity-85 text-[8px] sm:text-xs">
                        {subService.description} {/* Add more details as needed */}
                      </p>
                      <div className="flex justify-between items-center">



                        <p className="relative inline-block text-sm text-white font-bold">
                        ₹ {" "} <span className="line-through text-white font-light">
                        <span className="text-gray-400 font-light"> {subService.price} </span>  {/* Render the price */}
                      </span> {"  "}
                      <span className=" text-white font-light">
                        {subService.newPrice}  {/* Render the price */}
                      </span> 
                          
                          {/* <span className="absolute inset-0 top-1/2 h-[2px] bg-black"></span> */}


                        </p>

                        {/* <p>  {subService.newPrice}  </p> */}



                        {/* <p className="text-black">
           
       <span className="text-red-600">{`-${subService.discount}%`}</span>     {`₹${subService.newPrice}`} <span className="w-24 h-6 " 
        style={{
      background: 'linear-gradient(to right, rgba(167, 148, 50, 1), rgba(194, 180, 83, 1), rgba(217, 207, 110, 1), rgba(234, 226, 129, 1), rgba(244, 237, 141, 1), rgba(247, 241, 145, 1), rgba(241, 234, 138, 1), rgba(225, 215, 119, 1), rgba(199, 185, 88, 1), rgba(167, 148, 50, 1))'
    }}
       >  Save {`₹${(subService.price - subService.newPrice).toFixed(2)}`}</span>
     
          </p> */}

                        <div className="flex justify-between items-center gap-x-5">
                          <FaShoppingCart color="white" />


                          {/* <FaCartPlus color="white" /> */}
                          <FaCartPlus
  color="white"
  onClick={(e) => cartPlusIcon(e, subService)} // Pass the event object and service data
/>


                        </div>
   
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </Slider>
          </div>
        ))
      }
    </div>
  );
};

export default ServicePageNew;

