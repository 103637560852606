import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { FaCartPlus, FaShoppingCart } from "react-icons/fa";
import { FaStar } from "react-icons/fa6";
import img from "../assets/service.png";
import left from "../assets/ServiceDetails/left.png";
import right from "../assets/ServiceDetails/right.png";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import SERVER_URL from "../constants/constant";
import axios from "axios";
import "../screens/ServicePageCarousal.css";
import mixpanel from "../config/mixpanel.js";
import toast from "react-hot-toast";
const ServiceCarouselNew = () => {
  const [slideIndex, setSlideIndex] = useState(0);
  const [servicesData, setServicesData] = useState([]);
  const [cartAdded, setCartAdded] = useState(0);
  const navigate = useNavigate();

  const settings = {
    dots: true,
    infinite: true,
    speed: 800,
    slidesToShow: 3,
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: "40px",
    swipe: true, // Enable touchpad swipe
    swipeToSlide: true, // Swipe to slide one item at a time
    beforeChange: (current, next) =>{
       setSlideIndex(next);
        
    }
    ,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const handleService = (id) => {
    console.log(`Clicked on service with ID: ${id}`);
    mixpanel.track("BEST_SERVICES", {
      page: window.location.pathname,
      serviceId: id,
    });
    navigate("/servicepage/1", { state: { serviceId: id } });
  };

  function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    
    return (
      <img
        src={right}
        alt=""
        className={className}
        style={{ ...style, display: "block", width: "25px", height: "25px" }}
        onClick={onClick}
      />
    );
  }

  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <img
        src={left}
        alt=""
        className={className}
        style={{ ...style, display: "hidden", width: "25px", height: "25px" }}
        onClick={onClick}
      />
    );
  }
   

  const handleAddCart = (id) => {
    console.log("service id that is we are adding in our cart is in our carousenew page is  ",id)
    const modelId = JSON.parse(localStorage.getItem("vehicle-selected")).model._id;
    // const modelId=model.carModel;
    console.log("model id that is we are adding in out cart is ",modelId)
    
    const token = localStorage.getItem('TOKEN');
    
    axios
    .post(
      `${SERVER_URL}/user/cart/add`,
      { 
        serviceId: id, 
        modelId: modelId 
      },
      {
        withCredentials: true,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((response) => {
        console.log("%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%");
        console.log(response);
        const cartItems=response.data.userCart.cartItems;
        
        console.log("%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%");
        console.log(cartItems);
            localStorage.setItem("cartItems",JSON.stringify(cartItems))


        if (
          (response.data.success === true) &&
          (response.data.message === "successfully added to cart")
        ) {
          toast.success(response.data.message);
          setCartAdded(2);
          mixpanel.track("Added_to_Cart", { serviceId: id });
        } else if (response.data.success === true) {
          toast(response.data.message);
          setCartAdded(1);
        } else {
          toast.error(response.data.message);
        }
      })
      .catch((error) => {
        console.error("API Error:", error);
      });
  };

  useEffect(() => {
    axios
      .get(`${SERVER_URL}/service/getTopServices`)
      .then((response) => {
        console.log("respone of new in serviceCarouselnew page is ", response);
        // const { services } = response.data;
        const { dummyData: services } = response.data;
        // const services=response.data.dummyData


        if (services && services.length > 0) {
          setServicesData(services);
        } else {
          console.error("No services found in the response.");
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);
  return (
    <div className="px-4 sm:px-8 py-2">
      <div>
        <Slider {...settings}>
          {servicesData.map((service, index) => (
            <div
              className={
                index === slideIndex
                  ? "transform scale-125 sm:scale-100 my-10 md:my-0"
                  : "transform scale-0 sm:scale-[.75]"
              }
              key={index}
            >
              <div
                className="relative rounded-md cursor-pointer"
                onClick={() => handleService(service._id)}
              >
                <img src={img} alt="" className="rounded-md" />
                <div className="absolute bottom-0 p-2 sm:p-3.5 w-full">
                  <div className="flex justify-between items-center sm:mb-2">
                    <p className="text-xs sm:text-[22px] text-white font-bold">
                      {service.name}
                    </p>
                    <p className="text-white text-[8px] md:text-xs font-semibold py-0 sm:py-0.5 px-1 rounded bg-green-500 flex items-center gap-x-1">
                      {service.avgRating}
                      <FaStar color="white" />
                    </p>
                  </div>
                  <p className="hidden sm:block text-white opacity-85 text-[8px] sm:text-xs sm:mb-1">
                    {service.description}
                  </p>
                  <div className="flex justify-between items-center">
                    <p className="text-[10px] sm:text-base text-white font-bold">
                      Rs.{" "}
                      <span className="line-through text-gray-400 font-light">
                        {service.price}
                      </span>{" "}
                      {service.price}
                    </p>
                    <div className="flex justify-between items-center gap-x-5">
                      <FaShoppingCart color="white" />


                      
                     <button   onClick={() => handleAddCart(service._id)}>
                     <FaCartPlus  color="white" 
                       />
                      </button> 
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default ServiceCarouselNew;
