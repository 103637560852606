// import React, { useState, useEffect } from "react";
// // import NavbarUpdate from "../components/NavbarUpdate";
// import Popularpairing from "../components/popularpairing";
// import { BiSolidDiscount } from "react-icons/bi";
// import axios from "axios";
// import { Link } from "react-router-dom";
// import SERVER_URL from "../constants/constant";
// import TrackPageNavbar from "../components/TrackPageNavbar";
// import toast from "react-hot-toast";
// import { confirmAlert } from "react-confirm-alert";
// import "react-confirm-alert/src/react-confirm-alert.css";
// import mixpanel from "../config/mixpanel";
// import PromoCodes from "../components/PromoCodes";
// import { IoClose } from "react-icons/io5";
// import  { useContext } from 'react';
// import { CartContext } from "../context/CartContext";

// const CartInfo = () => {
//   // const [cartItems, setCartItems] = useState([]);
//   const [showPromo,setShowPromo]=useState(false);
//   const [appliedCoupon,setAppliedCoupon]=useState("Apply Promo Code");
//   const { cartItems,fetchCartItems,dispatch,cart} = useContext(CartContext);

//  console.log(cart);
//  console.log(cartItems);


//   useEffect(() => {
//     // Fetch cart items initially
//     fetchCartItems();
//     mixpanel.track("CART_INFO_PAGE_VIEW", {
//       page: window.location.pathname,
//     });
//   }, []);

//   useEffect(() => {
//     mixpanel.track("Viewed_Cart", { numItems: cartItems.length });
//   }, [cartItems]);

//   // const fetchCartItems = () => {
//   //   const token = localStorage.getItem('TOKEN');
//   //   axios
//   //     .get(`${SERVER_URL}/user/cart/get`, {
//   //       withCredentials: true, 
//   //       headers: {
//   //         Authorization: `Bearer ${token}`,
//   //       },
//   //     })
//   //     .then((response) => {
//   //       console.log(response.data);
        
//   //       setCartItems(response.data.cartItemsData || []);
        
         
//   //     })
//   //     .catch((error) => {
//   //       console.error("Error fetching data:", error);
//   //     });
//   // };

//   const calculateSubtotal = () => {
//     return cartItems
//       .reduce((subtotal, item) => {
        
//         return subtotal + (typeof item.price === "number" ? item.price : 0);
//       }, 0)
//       // .toFixed(2);
//   };

//   const calculateTax = () => {
//     const subtotal = calculateSubtotal();
   
//     const tax = 0.1 * subtotal; // Assuming tax is 10% of the subtotal
    
   
//     return parseFloat(tax.toFixed(2)); // Round to two decimal places
//   };

//   const calculateTotal = () => {
//     const subtotal = calculateSubtotal();
   
//     const tax = calculateTax();
 
//     return (subtotal + tax);
//   };

//   const handleCheckout = () => {
//     mixpanel.track("Check_Out", { numItems: cartItems.length });
//   };

//   const executeRemove = (service) => {
//     dispatch({ type: 'REMOVE_FROM_CART',payload:service });
//     console.log("item id that is clicked is ",service);
//     mixpanel.track("Removed_Item", { itemId:service._id });
//     const data = {
//       serviceId: service._id,
//     };

//     axios
//       .delete(`${SERVER_URL}/user/cart/remove`, {
//         data: data,
//         withCredentials: true,
//         headers: {
//           "Content-Type": "application/json",
//         },
//       })
//       .then((response) => {
//         console.log(response.data);
//         // Get the current cart items from localStorage
//         // const cartItems = JSON.parse(localStorage.getItem("cartItems")) || [];

//         // Remove the item with the specific itemId
//         // const updatedCartItems = cartItems.filter(item => item.id !== service._id);
     
//         // Update the localStorage with the filtered cart items
//         // localStorage.setItem("cartItems", JSON.stringify(updatedCartItems));
//         if (response.data.success === true) {
//           toast.success(response.data.message);
         
//         } else {
//           toast.error(response.data.message);
//         }
//         // After successful removal, fetch the updated cart items
        
//         fetchCartItems();
//       })
//       .catch((error) => {
//         console.error("Error removing item:", error);
//       });
//   };

//   const handleRemoveItem = (service) => {
//     console.log("service that has been clciekd to rmeove is ",service);
//     confirmAlert({
//       customUI: ({ onClose }) => {
//         return (
//           <div className="custom-ui border-black bg-white rounded p-6 shadow-md max-w-xs mx-auto">
//             <div className="relative mb-4">
//               <button
//                 onClick={onClose}
//                 className="absolute top-0 right-0 text-gray-600 focus:outline-none hover:text-gray-800"
//               >
//                 &times;
//               </button>
//               <h1 className="text-2xl font-bold mb-2">Remove from cart</h1>
//               <p className="text-gray-700 mb-2">
//                 Are you sure you want to delete this service?
//               </p>
//             </div>
//             <div className="border mb-2"></div>
//             <div className="flex justify-between w-full mx-auto">
//               <button
//                 onClick={onClose}
//                 className="bg-gray-300 text-black px-6 py-2 rounded focus:outline-none hover:bg-gray-400 border-r border-gray-400"
//               >
//                 Cancel
//               </button>

              
//               <button
//                 onClick={() => {
//                   // Execute the remove function if 'Remove' is clicked
//                   onClose();
//                   executeRemove(service);
//                 }}
//                 className="bg-red-500 text-white px-6 py-2 rounded focus:outline-none hover:bg-red-600 flex items-center"
//               >
//                 <svg
//                   xmlns="http://www.w3.org/2000/svg"
//                   fill="none"
//                   viewBox="0 0 24 24"
//                   stroke-width="1.5"
//                   stroke="currentColor"
//                   className="w-4 h-4 mr-1"
//                 >
//                   <path
//                     stroke-linecap="round"
//                     stroke-linejoin="round"
//                     d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
//                   />
//                 </svg>
//                 Remove
//               </button>
//             </div>
//           </div>
//         );
//       },
//       overlayClassName: "custom-overlay",
//       transition: "scale",
//     });
//   };
//   const goBack = () => {
//     window.history.back();
//   };

//   return (
//     <div className="flex flex-col">
//       <TrackPageNavbar showOnlyNav={false} />
//       <div class="px-4 sm:px-6 md:px-8 lg:px-14 py-3  flex flex-col lg:flex-row gap-1 lg:gap-0 justify-between lg:items-center">
//     <p class="text-sm text-gray-500 font-bold">
//     <a href="/" class="hover:text-black hover:cursor-pointer">Home</a> 
//               <span class="text-black"> / </span>
//                 <a href="/ServicePage" class="hover:text-black hover:cursor-pointer">Service</a>
//                 <span class="text-black"> / </span>
//                 <a href="javascript:void(0)" onclick={goBack} class="hover:text-black hover:cursor-pointer">Detail</a>
//                 <span class="text-black"> / </span>
//                 <span class="text-black">Cart</span>
//     </p>
// </div>

//       <div className="border-t-2 border-gray-200 my-1"></div>

//       <div class="max-w-8xl mx-auto md:mx-6 p-6  ">
//         <div class="grid lg:grid-cols-3 gap-1 relative">
//           <div class="lg:col-span-1">
//             <h2 class="text-3xl font-extrabold text-black mb-2">Cart</h2>
//             <h3 class="text-base text-black lg:whitespace-nowrap mb-2 lg:ml-1">
//               Review and finalize your selected items for a perfect checkout.
//             </h3>
//           </div>
//           <div class="lg:col-span-1 flex items-center justify-end">
//             <button
//               href="#"
//               type="button"
//               className="flex rounded-md font-semibold text-green-500 transition-all duration-200 ease-in-out hover:text-gray-900 mr-2"
//             >
//               <Link to="/ServicePage">+ Add more service</Link>
//             </button>
//           </div>
//         </div>

//         <div class="grid lg:grid-cols-3 gap-12 relative mt-18">
//           <div class="lg:col-span-2  border shadow-[0_0px_4px_0px_rgba(6,81,237,0.2)] ">
//             <div className="flex items-center justify-between mt-4 mx-4">
//               <p className="font-bold text-base ml-2 text-black-700 w-11/12">
//                 Service ({cartItems.length})
//               </p>
//               <p className="font-bold hidden md:block mr-8 ">Price</p>
//             </div>

//             <div className="flow-root mt-2 mb-2 max-h-[380px] overflow-y-auto">
//               {cartItems.map((item) => (
//                 <div key={item._id} className="p-6 rounded-md relative">
//                   <div className="flex max-sm:flex-col">
//                     <div className="w-52 shrink-0">
//                       <img
//                         src="/cartcarousel.png" 
//                         // Assuming 'photo' is the key for the image URL
//                         className="w-full h-full object-contain"
//                         alt={item.service.name}
//                       />
//                     </div>
//                     <div className="flex flex-col sm:flex-row w-full sm:border-l sm:pl-4  justify-between">
//                       <div className="sm:mr-4">
//                         <h3 className="text-xl font-bold text-[#333]">
//                           {item.service.name}
//                         </h3>
//                         <ul className="mt-2 text-sm text-[#333] space-y-2">
//                           <div className="flex items-center bg-green-500 p-2 rounded w-14 h-5 mt-3">
//                             <p className="text-sm font-bold text-gray-900 dark:text-white">
//                               {item.service.avgRating || "0"}
//                             </p>
//                             <svg
//                               className="w-3 h-3 text-white ml-1"
//                               aria-hidden="true"
//                               xmlns="http://www.w3.org/2000/svg"
//                               fill="currentColor"
//                               viewBox="0 0 22 20"
//                             >
//                               <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
//                             </svg>
//                             <div className="absolute top-0 right-6 flex sm:bottom-0 sm:top-auto">


//                               <button
//                                 type="button"
//                                 className="flex rounded p-2 mb-6 text-center md:mt-1 text-red-500 transition-all duration-200 ease-in-out focus:shadow hover:text-gray-900"
//                                 onClick={() => handleRemoveItem(item.service)}
//                               >
//                                 <svg
//                                   xmlns="http://www.w3.org/2000/svg"
//                                   fill="none"
//                                   viewBox="0 0 24 24"
//                                   stroke-width="1.5"
//                                   stroke="currentColor"
//                                   className="w-4 h-4"
//                                 >
//                                   <path
//                                     stroke-linecap="round"
//                                     stroke-linejoin="round"
//                                     d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
//                                   />
//                                 </svg>
//                                 <div>Remove</div>
//                               </button>


//                             </div>
//                           </div>
//                         </ul>
//                       </div>
//                       <div className="flex  mt-4 sm:mt-0">
//                         <div className="flex ">
//                           <h4 className="text-lg font-bold text-[#333] mr-2">
//                             ₹ {item.price}
//                           </h4>
//                         </div>
//                       </div>
//                     </div>
//                   </div>
//                 </div>
//               ))}
//             </div>

// {/* <div className="flow-root mt-2 mb-2 max-h-[380px] overflow-y-auto">
//   {cartItems.length > 0 ? (
//     cartItems.map((item) => (
//       <div key={item._id} className="p-6 rounded-md relative">
//         <div className="flex max-sm:flex-col">
//           <div className="w-52 shrink-0">
//             <img
//               src="/cartcarousel.png" 
//               className="w-full h-full object-contain"
//               alt={item.service.name}
//             />
//           </div>
//           <div className="flex flex-col sm:flex-row w-full sm:border-l sm:pl-4 justify-between">
//             <div className="sm:mr-4">
//               <h3 className="text-xl font-bold text-[#333]">
//                 {item.service.name}
//               </h3>
//               <ul className="mt-2 text-sm text-[#333] space-y-2">
//                 <div className="flex items-center bg-green-500 p-2 rounded w-14 h-5 mt-3">
//                   <p className="text-sm font-bold text-gray-900 dark:text-white">
//                     {item.service.avgRating || "0"}
//                   </p>
//                   <svg
//                     className="w-3 h-3 text-white ml-1"
//                     aria-hidden="true"
//                     xmlns="http://www.w3.org/2000/svg"
//                     fill="currentColor"
//                     viewBox="0 0 22 20"
//                   >
//                     <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
//                   </svg>
//                   <div className="absolute top-0 right-6 flex sm:bottom-0 sm:top-auto">
//                     <button
//                       type="button"
//                       className="flex rounded p-2 mb-6 text-center md:mt-1 text-red-500 transition-all duration-200 ease-in-out focus:shadow hover:text-gray-900"
//                       onClick={() => handleRemoveItem(item.service)}
//                     >
//                       <svg
//                         xmlns="http://www.w3.org/2000/svg"
//                         fill="none"
//                         viewBox="0 0 24 24"
//                         strokeWidth="1.5"
//                         stroke="currentColor"
//                         className="w-4 h-4"
//                       >
//                         <path
//                           strokeLinecap="round"
//                           strokeLinejoin="round"
//                           d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
//                         />
//                       </svg>
//                       <div>Remove</div>
//                     </button>
//                   </div>
//                 </div>
//               </ul>
//             </div>
//             <div className="flex mt-4 sm:mt-0">
//               <div className="flex ">
//                 <h4 className="text-lg font-bold text-[#333] mr-2">
//                   ₹ {item.price}
//                 </h4>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     ))
//   ) : (
//     <div className="p-6 text-center text-gray-500">
//       Your cart is empty.
//     </div>
//   )}
// </div> */}

//           </div>

//           <div class=" inset-0 bg-white h-max rounded-md p-6 shadow-[0_0px_4px_0px_rgba(6,81,237,0.2)] sticky top-0">
//             <div
//               className="block text-base font-bold text-black mb-2"
//             >
//               Promo Code
//             </div>
//             <div class="relative flex align-center justify-center">
//               <div class="absolute inset-y-0 start-0 flex  items-center ps-3 pointer-events-none">
//               <BiSolidDiscount className="w-6 h-6"/>
//               </div>
//               {/*<input
//                 type="Coupon"
//                 class="block w-full p-4 ps-10 text-sm text-gray-900 border border-gray-300 "
//                 placeholder="Apply Promo Code"
//                 required
//               />*/}
//               <div className="flex justify-between items-center w-full p-4 ps-12 text-sm text-gray-900 border border-gray-300 ">
//               {appliedCoupon}
              
//               {appliedCoupon!=="Apply Promo Code" && 
//               <div className=' mr-24'>
//               <IoClose className='w-4 h-4 cursor-pointer text-[#6B6B6B]' onClick={()=>setAppliedCoupon("Apply Promo Code")}/>
//               </div>}
//               </div>
//               <button
//                 type="submit"
//                 class=" absolute end-2.5 bottom-2.5  font-medium rounded-lg text-sm px-4 py-2 dark:bg-whitborder-transparent bg-transparent text-green-500 text-semibold hover:text-green-700 disabled:opacity-50 disabled:pointer-events-none "
//                 onClick={()=>setShowPromo(true)}
//               >
//                 Apply now
//               </button>
              
            
//             </div>
            
//             <div className="mt-4 border"></div>

//             <ul class="text-[#333] text-sm  mt-2">
//               <li class="flex flex-wrap gap-4 py-3 text-lg font-bold">
//                 Total
//                 <span class="ml-auto font-bold">₹{calculateSubtotal()}</span>
//               </li>
//               <li class="flex flex-wrap gap-4 py-3 text-lg font-bold">
//                 Tax <span class="ml-auto font-bold">₹{calculateTax()}</span>
//               </li>
//               <li class="border"></li>
//               <li class="flex flex-wrap gap-4 py-3 text-xl font-extrabold">
//                 Total Cost<span class="ml-auto">₹{calculateTotal()}</span>
//               </li>
//             </ul>
//             <button
//               type="button"
//               onClick={handleCheckout}
//               class="mt-6 text-sm px-6 py-2.5 w-full border-transparent bg-green-500 text-white hover:bg-green-700 disabled:opacity-50 disabled:pointer-events-none dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
//             >
//               <Link to="/paymentscreen">Check Out</Link>
//             </button>
//           </div>
//         </div>
//       </div>
//       <div className="border mx-8"></div>
//       <div className=" mx-4">
//         <div className="text-black font-bold text-2xl ml-10 mt-5">
//           Popular Pairing
//         </div>
//         <div className=" mx-2 md:mx-10 max-w-8xl mb-4">
//           <Popularpairing />
//         </div>
//         {showPromo && 
//             <PromoCodes onClose={()=>setShowPromo(false)} setAppliedCoupon={setAppliedCoupon} appliedCoupon={appliedCoupon}/>
//               }
//       </div>
//     </div>
//   );
// };

// export default CartInfo;






import React, { useState, useEffect } from "react";
// import NavbarUpdate from "../components/NavbarUpdate";
import Popularpairing from "../components/popularpairing";
import { BiSolidDiscount } from "react-icons/bi";
import axios from "axios";
import { Link } from "react-router-dom";
import SERVER_URL from "../constants/constant";
import TrackPageNavbar from "../components/TrackPageNavbar";
import toast from "react-hot-toast";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import mixpanel from "../config/mixpanel";
import PromoCodes from "../components/PromoCodes";
import { IoClose } from "react-icons/io5";
import  { useContext } from 'react';
import { CartContext } from "../context/CartContext";

const CartInfo = () => {
  // const [cartItems, setCartItems] = useState([]);
  const [showPromo,setShowPromo]=useState(false);
  const [appliedCoupon,setAppliedCoupon]=useState("Apply Promo Code");


 

  const {removeFromCart,cart,fetchCartItems} = useContext(CartContext);
  console.log(cart);


  
  
  useEffect(() => {
    fetchCartItems(); // Fetch data
    mixpanel.track("CART_INFO_PAGE_VIEW", { page: window.location.pathname });
    mixpanel.track("Viewed_Cart", { numItems: cart.length });
  }, []);  // Single useEffect for both logic on mount
  


  // useEffect(() => {
  //   mixpanel.track("Viewed_Cart", { numItems: cartItems.length });
  // }, [cartItems]);
  useEffect(() => {
    mixpanel.track("Viewed_Cart", { numItems: cart.length });
  }, [cart]);

   

  // useEffect(() => {
  //   const fetchAndTrack = async () => {
  //     // Await fetchCartItems to ensure it completes before proceeding
  //     await fetchCartItems();
      
  //     console.log("Cart items fetched, proceeding with other logic...");
      
  //     // Mixpanel tracking after the cart items are fetched
  //     mixpanel.track("CART_INFO_PAGE_VIEW", {
  //       page: window.location.pathname,
  //     });
      
  //     // Any other logic that depends on the cart items being fetched
  //     console.log("Fetched cart:", cart);
  //   };
  
  //   fetchAndTrack(); 
  // }, []); 
 
  // useEffect(() => {
  //   const fetchAndTrack = async () => {
  //     await fetchCartItems();
  
  //     console.log("Cart items fetched, proceeding with other logic...");
  
  //     mixpanel.track("CART_INFO_PAGE_VIEW", {
  //       page: window.location.pathname,
  //       numItems: cart.length, 
  //     });
  //   };
  
  //   fetchAndTrack();
  // }, []); 
  
  
  const calculateSubtotal = () => {
    // return cartItems
    return cart

      .reduce((subtotal, item) => {
        
        return subtotal + (typeof item.price === "number" ? item.price : 0);
      }, 0)
      // .toFixed(2);
  };

  const calculateTax = () => {
    const subtotal = calculateSubtotal();
   
    const tax = 0.1 * subtotal; // Assuming tax is 10% of the subtotal
    
   
    return parseFloat(tax.toFixed(2)); // Round to two decimal places
  };

  const calculateTotal = () => {
    const subtotal = calculateSubtotal();
   
    const tax = calculateTax();
 
    return (subtotal + tax);
  };

  // const handleCheckout = () => {
  //   mixpanel.track("Check_Out", { numItems: cartItems.length });
  // };
  const handleCheckout = () => {
    mixpanel.track("Check_Out", { numItems: cart.length });
  };

  const executeRemove = (service) => {
    console.log("service that we are passing is",service);
   removeFromCart(service);
  }

  const handleRemoveItem = (service) => {
    console.log("service that has been clciekd to rmeove is ",service);
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui border-black bg-white rounded p-6 shadow-md max-w-xs mx-auto">
            <div className="relative mb-4">
              <button
                onClick={onClose}
                className="absolute top-0 right-0 text-gray-600 focus:outline-none hover:text-gray-800"
              >
                &times;
              </button>
              <h1 className="text-2xl font-bold mb-2">Remove from cart</h1>
              <p className="text-gray-700 mb-2">
                Are you sure you want to delete this service?
              </p>
            </div>
            <div className="border mb-2"></div>
            <div className="flex justify-between w-full mx-auto">
              <button
                onClick={onClose}
                className="bg-gray-300 text-black px-6 py-2 rounded focus:outline-none hover:bg-gray-400 border-r border-gray-400"
              >
                Cancel
              </button>

              
              <button
                onClick={() => {
                  // Execute the remove function if 'Remove' is clicked
                  onClose();
                  executeRemove(service);
                }}
                className="bg-red-500 text-white px-6 py-2 rounded focus:outline-none hover:bg-red-600 flex items-center"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  className="w-4 h-4 mr-1"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
                  />
                </svg>
                Remove
              </button>
            </div>
          </div>
        );
      },
      overlayClassName: "custom-overlay",
      transition: "scale",
    });
  };
  const goBack = () => {
    window.history.back();
  };

  return (
    <div className="flex flex-col">
      <TrackPageNavbar showOnlyNav={false} />
      <div class="px-4 sm:px-6 md:px-8 lg:px-14 py-3  flex flex-col lg:flex-row gap-1 lg:gap-0 justify-between lg:items-center">
    <p class="text-sm text-gray-500 font-bold">
    <a href="/" class="hover:text-black hover:cursor-pointer">Home</a> 
              <span class="text-black"> / </span>
                <a href="/ServicePage" class="hover:text-black hover:cursor-pointer">Service</a>
                <span class="text-black"> / </span>
                <a href="javascript:void(0)" onclick={goBack} class="hover:text-black hover:cursor-pointer">Detail</a>
                <span class="text-black"> / </span>
                <span class="text-black">Cart</span>
    </p>
</div>

      <div className="border-t-2 border-gray-200 my-1"></div>

      <div class="max-w-8xl mx-auto md:mx-6 p-6  ">
        <div class="grid lg:grid-cols-3 gap-1 relative">
          <div class="lg:col-span-1">
            <h2 class="text-3xl font-extrabold text-black mb-2">Cart</h2>
            <h3 class="text-base text-black lg:whitespace-nowrap mb-2 lg:ml-1">
              Review and finalize your selected items for a perfect checkout.
            </h3>
          </div>
          <div class="lg:col-span-1 flex items-center justify-end">
            <button
              href="#"
              type="button"
              className="flex rounded-md font-semibold text-green-500 transition-all duration-200 ease-in-out hover:text-gray-900 mr-2"
            >
              <Link to="/ServicePage">+ Add more service</Link>
            </button>
          </div>
        </div>

        <div class="grid lg:grid-cols-3 gap-12 relative mt-18">
          <div class="lg:col-span-2  border shadow-[0_0px_4px_0px_rgba(6,81,237,0.2)] ">
            <div className="flex items-center justify-between mt-4 mx-4">
              <p className="font-bold text-base ml-2 text-black-700 w-11/12">
                Service ({cart.length})
                {/* Service ({cartItems.length}) */}
              </p>
              <p className="font-bold hidden md:block mr-8 ">Price</p>
            </div>

            <div className="flow-root mt-2 mb-2 max-h-[380px] overflow-y-auto">
              {/* {cart.map((item) => ( */}
              {cart.length > 0 ? (
              cart.map((item) => (
              
                <div key={item?.service?._id} className="p-6 rounded-md relative">
                  <div className="flex max-sm:flex-col">
                    <div className="w-52 shrink-0">
                      <img
                        src="/cartcarousel.png" 
                        // Assuming 'photo' is the key for the image URL
                        className="w-full h-full object-contain"
                        // alt={item.name}
                        alt={item?.service?.name}
                      />
                    </div>
                    <div className="flex flex-col sm:flex-row w-full sm:border-l sm:pl-4  justify-between">
                      <div className="sm:mr-4">
                        <h3 className="text-xl font-bold text-[#333]">
                          {/* {item.name} */}
                          {item?.service?.name}
                        </h3>
                        <ul className="mt-2 text-sm text-[#333] space-y-2">
                          <div className="flex items-center bg-green-500 p-2 rounded w-14 h-5 mt-3">
                            <p className="text-sm font-bold text-gray-900 dark:text-white">
                              {/* {item.avgRating || "0"} */}
                              {item?.service?.avgRating || "0"}
                            </p>
                            <svg
                              className="w-3 h-3 text-white ml-1"
                              aria-hidden="true"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="currentColor"
                              viewBox="0 0 22 20"
                            >
                              <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
                            </svg>
                            <div className="absolute top-0 right-6 flex sm:bottom-0 sm:top-auto">


                              <button
                                type="button"
                                className="flex rounded p-2 mb-6 text-center md:mt-1 text-red-500 transition-all duration-200 ease-in-out focus:shadow hover:text-gray-900"
                                onClick={() => handleRemoveItem(item?.service)}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  stroke-width="1.5"
                                  stroke="currentColor"
                                  className="w-4 h-4"
                                >
                                  <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
                                  />
                                </svg>
                                <div>Remove</div>
                              </button>


                            </div>
                          </div>
                        </ul>
                      </div>
                      <div className="flex  mt-4 sm:mt-0">
                        <div className="flex ">
                          <h4 className="text-lg font-bold text-[#333] mr-2">
                            ₹ {item?.price}
                            {/* ₹ {item?.service?.modelPrice?.newPrice} */}
                          </h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))): (
                <div className="p-6 text-center">
                  <h3 className="text-lg font-bold text-[#333]">Your cart is empty.</h3>
                </div>
              )}
            </div>
            
          </div>

          <div class=" inset-0 bg-white h-max rounded-md p-6 shadow-[0_0px_4px_0px_rgba(6,81,237,0.2)] sticky top-0">
            <div
              className="block text-base font-bold text-black mb-2"
            >
              Promo Code
            </div>
            <div class="relative flex align-center justify-center">
              <div class="absolute inset-y-0 start-0 flex  items-center ps-3 pointer-events-none">
              <BiSolidDiscount className="w-6 h-6"/>
              </div>
              {/*<input
                type="Coupon"
                class="block w-full p-4 ps-10 text-sm text-gray-900 border border-gray-300 "
                placeholder="Apply Promo Code"
                required
              />*/}
              <div className="flex justify-between items-center w-full p-4 ps-12 text-sm text-gray-900 border border-gray-300 ">
              {appliedCoupon}
              
              {appliedCoupon!=="Apply Promo Code" && 
              <div className=' mr-24'>
              <IoClose className='w-4 h-4 cursor-pointer text-[#6B6B6B]' onClick={()=>setAppliedCoupon("Apply Promo Code")}/>
              </div>}
              </div>
              <button
                type="submit"
                class=" absolute end-2.5 bottom-2.5  font-medium rounded-lg text-sm px-4 py-2 dark:bg-whitborder-transparent bg-transparent text-green-500 text-semibold hover:text-green-700 disabled:opacity-50 disabled:pointer-events-none "
                onClick={()=>setShowPromo(true)}
              >
                Apply now
              </button>
              
            
            </div>
            
            <div className="mt-4 border"></div>

            <ul class="text-[#333] text-sm  mt-2">
              <li class="flex flex-wrap gap-4 py-3 text-lg font-bold">
                Total
                <span class="ml-auto font-bold">₹{calculateSubtotal()}</span>
              </li>
              <li class="flex flex-wrap gap-4 py-3 text-lg font-bold">
                Tax <span class="ml-auto font-bold">₹{calculateTax()}</span>
              </li>
              <li class="border"></li>
              <li class="flex flex-wrap gap-4 py-3 text-xl font-extrabold">
                Total Cost<span class="ml-auto">₹{calculateTotal()}</span>
              </li>
            </ul>
            <button
              type="button"
              onClick={handleCheckout}
              class="mt-6 text-sm px-6 py-2.5 w-full border-transparent bg-green-500 text-white hover:bg-green-700 disabled:opacity-50 disabled:pointer-events-none dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
            >
              <Link to="/paymentscreen">Check Out</Link>
            </button>
          </div>
        </div>
      </div>
      <div className="border mx-8"></div>
      <div className=" mx-4">
        <div className="text-black font-bold text-2xl ml-10 mt-5">
          Popular Pairing
        </div>
        <div className=" mx-2 md:mx-10 max-w-8xl mb-4">
          <Popularpairing />
        </div>
        {showPromo && 
            <PromoCodes onClose={()=>setShowPromo(false)} setAppliedCoupon={setAppliedCoupon} appliedCoupon={appliedCoupon}/>
              }
      </div>
    </div>
  );
};

export default CartInfo;
