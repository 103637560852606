import React, { useEffect, useState, useContext, useRef } from "react";
import Logo from "../assets/Logo.png";
import { Link } from "react-router-dom";
import { CartContext } from "../context/CartContext";
import { useWishlist } from "../context/WishlistContext";
//import Videos from '../assets/HM_final.mp4';
import { LuShoppingCart } from "react-icons/lu";
import { useNavigate } from "react-router-dom";
import { FaRegUserCircle } from "react-icons/fa";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import FavoriteIcon from "@mui/icons-material/Favorite";
import Badge from "@mui/material/Badge";
import Form from "./Form";
import Cookies from "universal-cookie";
import Avatar from "../assets/userAvatar.png";
import personal from "../../src/assets/profile/personal.png";
import order from "../../src/assets/profile/order.png";
import vehicle from "../../src/assets/profile/vehicle.png";
import notification from "../../src/assets/profile/notification.png";
import setting from "../../src/assets/profile/setting.png";
import terms from "../../src/assets/profile/terms.png";
import help from "../../src/assets/profile/help.png";
import logoutImg from "../../src/assets/profile/logout.png";
import { Tooltip, IconButton } from "@mui/material";
import userAdd from "../assets/profile/user-add.png";
import toast from "react-hot-toast";
import axios from "axios";
import SERVER_URL from "../constants/constant";
const cookies = new Cookies();

const profileSections = [
  {
    label: "Personal Information",
    to: "/profile/personalInformation",
    image: userAdd,
  },
  { label: "Order History", to: "/profile/order-history", image: order },
  { label: "My Vehicle", to: "/profile/my-vehicle", image: vehicle },
  { label: "Notifications", to: "/profile/notifications", image: notification },
  { label: "Settings", to: "/profile/settings", image: setting },
  {
    label: "Terms & Conditions",
    to: "/profile/terms-conditions",
    image: terms,
  },
  {
    label: "Subscriptions",
    image: help,
    hasDropdown: true,
    dropdownOptions: [
      { label: "Mechanic plan", to: "/profile/SubscriptionPlan" },
      { label: "Driver plan", to: "/profile/subscription/option2" },
      { label: "Cleaner plan", to: "/profile/subscription/option3" },
    ],
  },

  { label: "Help & Support", to: "/profile/help-support", image: help },
];




const TrackPageNavbar = ({ showOnlyNav }) => {
  const [showSubscriptionsDropdown, setShowSubscriptionsDropdown] = useState(false);
  const [showProfile, setShowProfile] = useState(false);
  
  const toggleSubscriptionsDropdown = (e) => {
    e.stopPropagation();
    setShowSubscriptionsDropdown((prev) => !prev);
  };


  const dropdownRef = useRef(null);
  const navigate = useNavigate();

  const [menu, setMenu] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [user, setUser] = useState(null);
  const [token, setToken] = useState();
  const [hamBurgerOptions, setHamburgerOptions] = useState(false);
  //const token = cookies.get("TOKEN");
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [showSignInMessage, setShowSignInMessage] = useState(false);
 
  const { wishlistItems } = useWishlist();

  // const [cartItems, setCartItems] = 

   const { cart } = useContext(CartContext);
  

  

  useEffect(() => {
    const closeDropdownOnOutsideClick = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownOpen(false);
      }
    };

    document.addEventListener("click", closeDropdownOnOutsideClick);

    return () => {
      document.removeEventListener("click", closeDropdownOnOutsideClick);
    };
  }, [isDropdownOpen]);

  useEffect(() => {
    const tokenVal = localStorage.getItem("TOKEN");
    setToken(tokenVal);
  }, []);

  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem("user"));
    setUser(userData);
  }, [token]);


  // useEffect(()=>
  // {
  //   const cartLength=JSON.parse(localStorage.getItem("cartItems"));
  // },[cartItems])


  // const logout = () => {
  //   localStorage.removeItem("user");
  //   localStorage.removeItem("TOKEN");
  //   //cookies.remove("TOKEN", { path: "/" });
  //   window.location.href = "/";
  // };

  
  const handleLoginClick = (e) => {
    e.preventDefault();
    console.log(e.target.to);

    navigate("/LoginPage");
  };



  const logout = () => {
    localStorage.removeItem("user");
    localStorage.removeItem("TOKEN");
    setToken(null); // Update the token state to null
    window.location.href = "/";
  };

  const toggleDropdown = () => {
    if (!token) {
      toast.error("Please sign in to access your profile.", {
        duration: 5000,
        position: "top-center",
        style: {
          background: "#ff4444", // Background color
          color: "#ffffff", // Text color
          boxShadow: "0 0 10px rgba(0,0,0,0.2)", // Box shadow
        },
        iconTheme: {
          primary: "#ffffff", // Icon color
          secondary: "#ff4444", // Icon background color
        },
      });

      // Wait for 2 seconds (2000 milliseconds) before redirecting
      setTimeout(() => {
        window.location.href = "/LoginPage";
      }, 500);
    } else {
      setDropdownOpen(!isDropdownOpen);
      if (menu) {
        setMenu(!menu);
      }
    }
  };

  const toggleHamburgerOptions = () => {
    setMenu(!menu);
    if (isDropdownOpen) {
      setDropdownOpen(!isDropdownOpen);
    }
  };
  
  
  // const cartItems = JSON.parse(localStorage.getItem('cartItems'));
// console.log("cartitems inside the treacknabvbar",cartItems);
console.log("cartitems inside the treacknabvbar",cart);
  

  return (
<div className="flex w-full items-center z-20 flex-col gap-0 relative shadow-md">
<div
  className={
    menu
      ? "flex flex-col w-full bg-black md:bg-transparent text-white px-8 py-2"
      : showOnlyNav
      ? "flex flex-col w-full  bg-black text-white px-4 md:px-8 py-2"
      : "flex flex-col w-full bg-gradient-to-b  text-white px-4 md:px-8 xl:px-14 py-2"
  }
>
  <div className="flex items-center justify-between">
    <div className="flex w-[63%] space-x-3 items-center">
      <img
        src={Logo}
        alt="logo"
        className="w-[70px] h-[70px] object-contain"
      />
      <ul className="ml-4 w-full justify-end text-lg items-center hidden md:flex lg:flex xl:flex md:space-x-4 lg:space-x-8">
        <li className="nav-item relative group">
          <Link
            className="text-black hover:no-underline text-start relative"
            to="/"
          >
            Home
            <span className="absolute bottom-0 left-0 w-0 h-[2px] bg-green-500 transition-all duration-300 ease-in-out group-hover:w-full"></span>
          </Link>
        </li>
        <li className="nav-item relative group">
          <Link
            className="text-black hover:no-underline text-start relative"
            to="/ServicePage"
          >
            Services
            <span className="absolute bottom-0 left-0 w-0 h-[2px] bg-green-500 transition-all duration-300 ease-in-out group-hover:w-full"></span>
          </Link>
        </li>
        <li className="nav-item relative group">
          <Link
            className="text-black hover:no-underline text-start relative"
            to="https://helpystore.com/" target="_blank"
          >
            HelpyStore
            <span className="absolute bottom-0 left-0 w-0 h-[2px] bg-green-500 transition-all duration-300 ease-in-out group-hover:w-full"></span>
          </Link>
        </li>
        <li className="nav-item relative group">
          <Link
            className="text-black  hover:no-underline text-start relative"
            to="/About"
          >
            About Us
            <span className="absolute bottom-0 left-0 w-0 h-[2px] bg-green-500 transition-all duration-300 ease-in-out group-hover:w-full"></span>
          </Link>
        </li>
      </ul>
    </div>
    <div className="flex items-center md:hidden lg:hidden xl:hidden justify-between w-[33%] sm:w-[15%] mr-3">
    <div className="w-[35%] sm:w-[45%]">
    {!token || token === null ? (
  <Link
    to="/LoginPage"
    className="bg-green-500 md:w-[60%] sm:w-[100%] py-[12%] px-[30%] text-center md:text-base rounded-md hover:scale-110 transform transition duration-300 ease-in-out font-semibold"
  >
    Login
  </Link>
) : (
  <div className="relative flex md:flex justify-end">
    <FaRegUserCircle onClick={toggleDropdown} color="black" />
    {isDropdownOpen && (
      <div className="origin-top-right absolute right-0 top-10 mt-2 w-40 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
        <div className="py-1">
          
          <Link
            to={"/ProfilePage"}
            className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
          >
            My Profile
          </Link>
          <div
            onClick={() => logout()}
            className="block px-4 py-2 cursor-pointer text-sm text-red-600 hover:bg-red-100"
          >
            Sign Out
          </div>
        </div>
      </div>
    )}
  </div>
)}
</div>

      <div className="w-[30%] sm:w-[35%]">
        <div
          className="w-[35px] h-[25px] flex flex-col justify-between cursor-pointer text-white"
          onClick={toggleHamburgerOptions}
        >
          <div className="h-[3px] bg-black w-full"></div>
          <div className="h-[3px] bg-black w-full"></div>
          <div className="h-[3px] bg-black w-full"></div>
        </div>
      </div>
    </div>
    <div className="hidden md:flex md:items-center w-[20%] justify-end relative">
      {token && token !== null && (


        <Link to={"/cartinfo"}>
          <Tooltip title="Cart">
            <IconButton>

            {
              cart.length >=0 &&
              <span className=" absolute  -top-1 -right-2 bg-green-900 text-xs w-5 h-5 flex justify-center items-center animate-bounce rounded-full text-white ">{cart.length}</span>
            }
           
              <ShoppingCartOutlinedIcon className="w-6 h-6 text-black" />
            </IconButton>
          </Tooltip>
        </Link>

        
      )}
      <div
        className="relative md:flex md:items-center md:space-x-4 md:w-[21%] lg:w-[18%] justify-end"
        ref={dropdownRef}
      >
        {token && token !== null ? (
          <Tooltip title="Profile">
            <IconButton>
              <FaRegUserCircle
                onClick={toggleDropdown}
                color="black"
                className="w-6 h-6 cursor-pointer "
              />
            </IconButton>
          </Tooltip>
        ) : (
          <Link
  onClick={handleLoginClick}
  to="/LoginPage"
  className="bg-white-500 text-black border border-black  hover:border-green-600 w-full md:w-auto md:px-4 py-2 text-center text-sm lg:text-base rounded-md hover:bg-green-500 hover:text-white hover:scale-110 transform transition duration-300 ease-in-out font-semibold"
>
  Login
</Link>

        )}
        {isDropdownOpen && (
          <div className="origin-top-right absolute right-3 top-7 mt-2 w-60 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-50">
            <div className="p-2 ">
            {profileSections.map((section, index) => (
        <div key={index}>
          {section.hasDropdown ? (
            <div className="relative w-full">
              <button
                 onClick={(e) => {
                  e.stopPropagation();
                  toggleSubscriptionsDropdown(e);
                }}
                className="flex items-center justify-between w-full  font-semibold px-2 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:rounded"
              >
                <div className="flex items-center gap-x-2">
                
                
                <div className="bg-green-100 rounded-[50%] w-8 h-8 flex justify-center items-center">
                  <img src={section.image} alt="" className="w-5 h-5" />
                </div>
                {section.label}
                </div>
                
                <svg
              className={`w-4 h-4 transition-transform transform ${
                showSubscriptionsDropdown ? "rotate-180" : ""
              }`}
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M19 9l-7 7-7-7"
              />
            </svg>
              </button>
              {showSubscriptionsDropdown && (
                <div className="mt-2 pl-6 relative left-0 w-full">
                  <ul className="list-disc pl-10">
                    {section.dropdownOptions.map((option, idx) => (
                      <li key={idx} className="text-gray-700 hover:bg-gray-100 hover:rounded px-2 py-1 cursor-pointer"
                      style={{ paddingLeft: '2px' }}
                      >
                        <Link
                          to={option.to}
                          className="block text-sm text-gray-700 hover:bg-gray-100 hover:rounded"
                          style={{ padding: '4px 0', margin: '2px 0' }}
                        >
                          {option.label}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
          ) : (
            // Render other sections without dropdown
            <Link
              to={section.to}
              className={`flex items-center gap-x-2 font-semibold px-2 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:rounded ${
                index === profileSections.length - 1 && "mb-4"
              }`}
            >
              <div className="bg-green-100 rounded-[50%] w-8 h-8 flex justify-center items-center">
                <img src={section.image} alt="" className="w-5 h-5" />
              </div>
              {section.label}
            </Link>
          )}
        </div>
      ))}
              <div className="border-t-2 border-dotted pt-1.5">
                <div
                  onClick={() => logout()}
                  className="flex items-center pl-4 gap-x-[11px] px-2 py-3.5 font-semibold cursor-pointer text-sm text-red-600 hover:bg-red-100 hover:rounded"
                >
                  <img src={logoutImg} alt="" className="w-5 h-5" />
                  Sign Out
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  </div>
  <div
    className={
      menu
        ? "md:hidden z-40  duration-1000 ease-in-out transform transition"
        : " z-40 h-[0px] hidden duration-100000 ease-in-out transform transition"
    }
  >
    <div className="flex w-full pb-1 justify-end">
      <ul className=" text-lg items-end flex flex-col ">
        <li className="nav-item after:transition-all after:duration-300 after:ease-in-out after:flex after:absolute after:w-0 after:hover:w-[85px] after:bg-green-400 after:h-[2px] -bottom-[2px] w-[85px] text-end ">
          <Link
            className="text-white hover:text-white hover:no-underline text-end"
            to="/"
          >
            Home
          </Link>
        </li>
        <li className="nav-item after:transition-all after:duration-300 after:ease-in-out after:flex after:absolute after:w-0 after:hover:w-[85px] after:bg-green-400 after:h-[2px] -bottom-[2px] w-[85px] text-end">
          <Link
            className="text-white hover:text-white hover:no-underline text-end"
            to="/"
          >
            Services
          </Link>
        </li>
        <li className="nav-item after:transition-all after:duration-300 after:ease-in-out after:flex after:absolute after:w-0 after:hover:w-[85px] after:bg-green-400 after:h-[2px] -bottom-[2px] w-[85px] text-end">
          <Link
            className="text-white hover:text-white hover:no-underline text-end"
            to="https://helpystore.com/" target="_blank"
          >
            HelpyStore
          </Link>
        </li>
        <li className="nav-item after:transition-all after:duration-300 after:ease-in-out after:flex after:absolute after:w-0 after:hover:w-[85px] after:bg-green-400 after:h-[2px] -bottom-[2px] w-[85px] text-end">
          <Link
            className="text-white hover:text-white hover:no-underline text-end"
            to="/"
          >
            About Us
          </Link>
        </li>
      </ul>
    </div>
  </div>
</div>
{/* Sign-in message */}
{showSignInMessage && (
  <div className="fixed top-4 right-4 bg-white p-4 border border-gray-300 rounded shadow-md z-50">
    <p className="text-gray-800">
      Please sign in to access your profile.
    </p>
    <button
      className="bg-green-500 text-white px-4 py-2 rounded mr-2"
      onClick={() => (window.location.href = "/LoginPage")}
    >
      Sign In
    </button>
    <button
      className="border border-gray-300 px-4 py-2 rounded"
      onClick={() => setShowSignInMessage(false)}
    >
      Close
    </button>
  </div>
)}
</div>
);
};

export default TrackPageNavbar;