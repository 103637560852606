// import React, { createContext, useReducer, useEffect } from "react";
// import { cartReducer } from "./CartReducer";

// // import axios from "axios";
// // import SERVER_URL from "../constants/constant";
// // import { useState } from 'react';
// export const CartContext = createContext();

// export const CartProvider = ({ children }) => {
//   const [cart, dispatch] = useReducer(cartReducer, [], (initial) => {
//     const localData = localStorage.getItem("cartItems");
//     return localData ? JSON.parse(localData) : initial;
//   });

//   // Calculate cartTotal
//   const cartTotal = cart.reduce(
//     (total, item) =>
//       item.discount === null
//         ? total + item.basePrice
//         : total + parseInt(item.basePrice * ((100 - item.discount) / 100)),
//     0
//   );

//   useEffect(() => {
//     localStorage.setItem("cartItems", JSON.stringify(cart));
//   }, [cart]);

//   console.log("cart is inside the caet context is ", cart);
//   return (
//     <CartContext.Provider value={{ cart, dispatch, cartTotal }}>
//       {children}
//     </CartContext.Provider>
//   );
// };


// new code  is added for centralization
import React, { createContext, useReducer, useEffect,useState } from "react";
import axios from "axios";
import { cartReducer } from "./CartReducer";
import SERVER_URL from "../constants/constant";
import toast from "react-hot-toast";
import mixpanel from "../config/mixpanel";

export const CartContext = createContext();
export const CartProvider = ({ children }) => {
const [cart, dispatch] = useReducer(cartReducer, [{}]);
const [cartItems, setCartItems] = useState([]);
const [previousCart, setPreviousCart] = useState([{}]);

  const fetchCartItems = async () => {
    try {  console.log("Fetching cart items...");
      const token = localStorage.getItem("TOKEN");
      const response  = await  axios
      .get(`${SERVER_URL}/user/cart/get`, {
        withCredentials: true, 
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      console.log("response.data inside fetchCartItems:", response.data);


      console.log("response inside the cart context after fethcing it from the api",response.data.cartItemsData);
      setCartItems(response.data.cartItemsData);
      dispatch({
        type: "SET_CART",
        payload: response.data.cartItemsData || [], 
      });
    } catch (error) {
      console.error("Error fetching cart items:", error);
    }
  };

  const addToCart = async (service) => {
    try {
      // mixpanel.track("Added_Item", { itemId: service._id });
      console.log("service inseide the cart context",service);
      dispatch({ type: "ADD_TO_CART", payload: service });
      const token = localStorage.getItem("TOKEN");
      const modelId = JSON.parse(localStorage.getItem("vehicle-selected")).modelId;
   
      const response = await axios.post(
        `${SERVER_URL}/user/cart/add`,
        {
          serviceId: service._id,
          modelId: modelId,
        },
        {
          withCredentials: true,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
     console.log("response inside the cart context  after adding the services inside the cart",response);
      if (
        response.data.success === true 
        // &&
        // response.data.message === "service already in the cart"
      ) {
       
        toast.success(
          "Service added suceessfully in cart"
        );
       
        mixpanel.track("Added_to_Cart", { serviceId: service._id });
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.error("Error adding to cart:", error);
    }
  };


  const removeFromCart = async (service) => {
    // Dispatch the action to update the state first
    
    mixpanel.track("Removed_Item", { itemId: service?._id });
    dispatch({ type: 'REMOVE_FROM_CART', payload: service });
    const data = {
      serviceId: service?._id,
    };
  
    try {
      const token = localStorage.getItem("TOKEN");
      const response = await axios.delete(`${SERVER_URL}/user/cart/remove`, {
        data: data,
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`, // Added Authorization header
        },
      });
  
      if (response.data.success === true) {
        dispatch({ type: 'REMOVE_FROM_CART', payload: service });
        toast.success(response.data.message);
      } else {
        toast.error(response.data.message);
      }
  
      // Fetch the updated cart items after removal
      fetchCartItems();
    } catch (error) {
      console.error("Error removing item:", error); // Corrected catch syntax
    }
  };
  useEffect(() => {
    console.log("Cart has changed:", cart); // Log when the cart changes
  }, [cart]);
  const clearCart = () => {
    setPreviousCart(cart); // Store the current cart before clearing
    dispatch({ type: 'CLEAR_CART' });
    setCartItems([]); // Clear cartItems state
    toast.success("Cart cleared successfully!");
  };
  // Calculate total cart price
  // const cartTotal = cart.reduce((total, item) => {
  //   return item.discount === null
  //     ? total + item.basePrice
  //     : total + parseInt(item.basePrice * ((100 - item.discount) / 100));
  // }, 0);
  useEffect(() => {
    fetchCartItems();
  }, []);
  return (
    <CartContext.Provider
      value={{
        cart,
        cartItems,
        previousCart,  
        addToCart,
        removeFromCart,
        fetchCartItems,
        clearCart,
        setCartItems,
        dispatch,

      }}
    >
      {children}
    </CartContext.Provider>
  );
};

