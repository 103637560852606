
import React from 'react'

const HelpAndSupport = () => {
  return (
    <div class="flex items-center justify-center h-screen">
    <div class="bg-green-500 p-4 text-white">Help & Support </div>
  </div>
  
  )
}

export default HelpAndSupport
