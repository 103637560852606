import React, { useState, useEffect, useContext, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { LocationData } from "../context/LocationContext";
import TrackPageNavbar from "../components/TrackPageNavbar";
import InputSuggestionMaps from "../components/InputSuggestionMaps";
import { socket } from "../utils/socketConfigure";
import {
  APIProvider,
  Map,
  Pin,
  InfoWindow,
  Marker,
} from "@vis.gl/react-google-maps";
import Xarrow from "react-xarrows";
import Trackorderstep1 from "../components/trackorderstep1";
import Trackorderstep2 from "../components/trackorderstep2";
import Trackorderstep3 from "../components/trackorderstep3";
import Trackorderstep4 from "../components/trackorderstep4";
import Trackorderstep5 from "../components/trackorderstep5";
import DirectionsComponent from "../components/DirectionComponent";
import Trackdirections from "../components/Trackdirections"

const Trackorder = () => {



  const handleStepClick = (step) => {
    console.log(step);
   
    setCurrentStep(step);
    setCurrentTrack(`Stage${step-1}`)
    console.log(`Stage${step}`);
  };

  const MAP_API = process.env.REACT_APP_MAP_API;
  const [address, setAddress] = useState()
  const [reRouteDetails, setReRouteDetails] = useState(null);
  const mapRef = useRef(null);
  const { locationdispatch } = useContext(LocationData);
  const [loading, setLoading] = useState(false);
  const [coords, setCoords] = useState({});
  const [nearByRange, setNearByRange] = useState(500);
  const [currentStep, setCurrentStep] = useState(2);
  const [directionsDetails, setDirectionsDetails] = useState(null);
  const [currentTrack, setCurrentTrack] = useState("Stage1");
  const [currentLocation, setCurrentLocation] = useState(null);
  const [Count, setCount] = useState(0);
  const step1 = useRef(null);
  const step2 = useRef(null);
  const step3 = useRef(null);
  const step4 = useRef(null);
  const step5 = useRef(null);
  const step6 = useRef(null);
  

  const imageStyles = {
    width: "60px",
    height: "50px",
  };


  // socket.connect();

  // console.log("IS Socket connected with backend ?", socket);

  // socket.emit("fetch-ticket", {
  //   ticketId: "65da1d497778afc7598e6cb9",
  //   room: "mechanicTickets",
  // });

  // useEffect(() =>{
  //   socket.emit("update-location", {
  //     ticketId: "65da1d497778afc7598e6cb9",
  //     location: [18.532521, 73.829993],
  //     room: "mechanicTickets",
  //   });  
  //  },[Count]);

  //  const intervalid= setInterval (()=>{
  //   setCount(Count+1)
  //  },60000)


  // socket.on('processed', (data) => {
  //   console.log("&&&&&&&&&&&&&&&&");
  //   console.log(data);
  //   if(data.message === 'Ticket location updated successfully'){
  //     setCurrentLocation({
  //       lat: data.ticket.currentLocation.coordinates[0],
  //       lng: data.ticket.currentLocation.coordinates[1]
  //     })
  //     console.log(currentLocation)   
  //   }
  //   if(data.message === 'Ticket Fetched'){
  //     setCurrentTrack(data.ticket.currentTrack)
  //     console.log(currentTrack)
  //     const Track =  data.ticket.currentTrack
  //     setCurrentStep(parseInt(Track.slice(Track.length-1)))
  //   }
  //   if (currentTrack === "stage1" && data.message === 'Route Recalculated') {
  //     console.log("Start and End Address are:", {
  //       start: data.route.start_address,
  //       end: data.route.end_address
  //     });
  
  //     let directions = {
  //       status: true,
  //       origin: data.route.start_address,
  //       destination: data.route.end_address
  //     };
  //     setDirectionsDetails(directions);
  
  //     let state = {
  //       eta: data.route.time,
  //       mechanicAddress: data.route.start_address
  //     };
  //     setReRouteDetails(state);
  //   } 
  
  //   if (currentStep === 2 && data.message === 'Route Calculated') {
  //     console.log("Start and End Address are:", {
  //       start: data.route.start_address,
  //       end: data.route.end_address
  //     });
  
  //     let directions = {
  //       status: true,
  //       origin: data.route.start_address,
  //       destination: data.route.end_address
  //     };
  //     setDirectionsDetails(directions);
  
  //     let state = {
  //       eta: data.route.time,
  //       mechanicAddress: data.route.end_address 
  //     };
  //     setReRouteDetails(state);
  //   }
  // });
  
  

  return (
    <div>
     
      <TrackPageNavbar showOnlyNav={false} />
      <div className="px-4 md:px-14 py-4 shadow-sm">
        <p className="text-sm text-gray-500 font-bold">
          <Link to="/">
          Profile /{" "}
          </Link>
          <Link to="/profile/order-history">
            <span className="hover:text-black">Order history </span>
          </Link>
          / <span className="text-black">Track Order</span>
        </p>
      </div>
      <div className="px-4 md:px-14 my-2">
        <p className="text-xl font-bold mb-2 max-w-max relative after:h-[1.5px] after:bg-green-400 after:mt-0.5 after:w-[40px] after:flex after:absolute after:right-0.5">
          Track Order
        </p>



        <div
          className="w-11/12 lg:w-full
                    xl:flex xl:flex-wrap xl:justify-around
                    lg:flex lg:flex-row lg:justify-around
                    md:flex md:flex-row md:justify-between"
        >
          <div
            className="xl:flex xl:flex-col xl:items-center
                        lg:flex lg:flex-col lg:items-center
                        md:flex md:flex-col md:items-start
                        sm:flex sm:flex-col sm:items-center
                        flex flex-col items-center"
          >
            <img
              src={require("../assets/OrderDetailsPage/Group 984.png")}
              style={imageStyles}
              ref={step1}
              onClick={() => handleStepClick(1)}
              alt=""
              className={currentTrack ==="Stage1" ? "bold-icon" : ""}
           
            />
            <p className="xl:w-40 lg:w-32 md:w-26 text-center my-8 lg:my-0 md:my-0">
              Mechanic Accepted service
            </p>
          </div>


          <div
            className="xl:flex xl:flex-col xl:items-center
                        lg:flex lg:flex-col lg:items-center
                        md:flex md:flex-col md:items-center
                        sm:flex sm:flex-col sm:items-center
                        flex flex-col items-center"
          >
            <img
              src={require("../assets/OrderDetailsPage/Group 985.png")}
              style={imageStyles}
              ref={step2}
              onClick={() => handleStepClick(2)}
              alt=""
              className={currentStep === 2 ? "bold-icon" : ""}
            />
            <p className="xl:w-40 lg:w-32 md:w-32 text-center my-8 lg:my-0 md:my-0">
              Mechanic picked the vehicle
            </p>
          </div>


          <div
            className="xl:flex xl:flex-col xl:items-center
                        lg:flex lg:flex-col lg:items-center
                        md:flex md:flex-col md:items-center
                        sm:flex sm:flex-col sm:items-center
                        flex flex-col items-center"
          >
            <img
              src={require("../assets/OrderDetailsPage/Group 987.png")}
              style={imageStyles}
              ref={step3}
              onClick={() => handleStepClick(3)}
              alt=""
              className={currentStep === 3 ? "bold-icon" : ""}
            />
            <p className="xl:w-40 lg:w-32 md:w-32 text-center my-8 lg:my-0 md:my-0">
              Mechanic reached the workshop
            </p>
          </div>


          <div
            className="xl:flex xl:flex-col xl:items-center
                        lg:flex lg:flex-col lg:items-center
                        md:flex md:flex-col md:items-center
                        sm:flex sm:flex-col sm:items-center
                        flex flex-col items-center"
          >
            <img
              src={require("../assets/OrderDetailsPage/car 1.png")}
              style={imageStyles}
              ref={step4}
              onClick={() => handleStepClick(4)}
              alt=""
              className={currentStep === 4 ? "bold-icon" : ""}
            />
            <p className="xl:w-40 lg:w-32 md:w-32 text-center my-8 lg:my-0 md:my-0">
              Service in progress
            </p>
          </div>


          <div
            className="xl:flex xl:flex-col xl:items-center
                        lg:flex lg:flex-col lg:items-center
                        md:flex md:flex-col md:items-center
                        sm:flex sm:flex-col sm:items-center
                        flex flex-col items-center"
          >
            <img
              src={require("../assets/OrderDetailsPage/Group 33310.png")}
              style={imageStyles}
              ref={step5}
              onClick={() => handleStepClick(5)}
              alt=""
              className={currentStep === 5 ? "bold-icon" : ""}
            />
            <p className="xl:w-40 lg:w-32 md:w-32 text-center my-8 lg:my-0 md:my-0">
              Service Done & out for delivery
            </p>
          </div>


          <div
            className="xl:flex xl:flex-col xl:items-center
                      lg:flex lg:flex-col lg:items-center
                      md:flex md:flex-col md:items-center
                      sm:flex sm:flex-col sm:items-center
                      flex flex-col items-center"
          >
            <img
              src={require("../assets/OrderDetailsPage/payment 1.png")}
              style={imageStyles}
              ref={step6}
              onClick={() => handleStepClick(6)}
              alt=""
              className={currentStep === 6 ? "bold-icon" : ""}
            />
            <p className="xl:w-40 lg:w-32 md:w-32 text-center my-8 lg:my-0 md:my-0">
              Payment Completed
            </p>


          </div>



          <Xarrow
            start={step1}
            end={step2}
            color={currentStep >= 2 ? "#22C55D" : "grey"}
            // color={currentStep >= 1 ? "#22C55D" : "grey"}
            showHead={false}
            dashness
          />


          <Xarrow
            start={step2}
            end={step3}
            color={currentStep >= 3 ? "#22C55D" : "grey"}
            // color={currentStep >= 2 ? "#22C55D" : "grey"}
            showHead={false}
            dashness
          />


          <Xarrow
            start={step3}
            end={step4}
            color={currentStep >= 4 ? "#22C55D" : "grey"}
            // color={currentStep >= 3 ? "#22C55D" : "grey"}
            showHead={false}
            dashness
          />


          <Xarrow
            start={step4}
            end={step5}
            color={currentStep >= 5 ? "#22C55D" : "grey"}
            // color={currentStep >= 4? "#22C55D" : "grey"}
            showHead={false}
            dashness
          />


          <Xarrow
            start={step5}
            end={step6}
            color={currentStep >= 6 ? "#22C55D" : "grey"}
            // color={currentStep >= 5 ? "#22C55D" : "grey"}
            showHead={false}
            dashness
          />
        </div>












        <div className="w-full">
        {currentTrack === "Stage5" && <Trackorderstep5 />}
        </div>









        <div className="flex flex-col md:flex-row md:mt-7 gap-5 overflow-y-hidden">
          {/* Map Section */}
        
          {currentStep !== 6 && (
            <div className="w-full md:w-4/6 p-2 py-2">
            <div className="col-span-1 md:col-span-2 lg:col-span-2 xl:col-span-2 flex flex-col gap-1 h-full">
                <div className="relative w-full h-96">
                  <APIProvider apiKey={MAP_API}>
                    <InputSuggestionMaps Set_coords={setCoords} Set_range={setNearByRange} Coordinates={coords} Address={setAddress} />
                    <div className="w-full h-full">
                      <Map defaultZoom={12} defaultCenter={coords} ref={mapRef}>
                        <Marker position={currentLocation} />
                        {
                          directionsDetails && directionsDetails.status === true && <Trackdirections Route={directionsDetails} />
                        }   
                      </Map>
                   
                    </div>
                  </APIProvider>
                </div>

              
              </div>
            </div>
          )}




          <div className="w-full md:w-2/6 p-2 ">
         
            {currentTrack === "Stage1" && <Trackorderstep1/>}
            {/* {currentTrack === "Stage1" && <Trackorderstep1 socket={socket} setDirectionsDetails={setDirectionsDetails} />} */}
           
            {currentTrack === "Stage2" && <Trackorderstep2/>}
            {/* {currentTrack === "Stage2" && <Trackorderstep2 socket={socket} setDirectionsDetails={setDirectionsDetails}/>} */}
            {currentTrack === "Stage3" && <Trackorderstep3 />}
            {currentTrack === "Stage4" && <Trackorderstep4 />}
            {/* {currentStep === 6 && <Trackorderstep6 />}  */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Trackorder;
