import React, { useState, useEffect } from "react";
//import { socket } from "../utils/socketConfigure";

const Trackorderstep2 = () => {
// const Trackorderstep2 = ({socket,setDirectionsDetails}) => {
  const [scheduledArrivedOTP, setScheduledArrivedOTP] = useState("");
  const [reRouteDetails, setReRouteDetails] = useState(null);

  // socket.on("processed", (data) => {
  //   if (data.message === "Route Calculated") {
  //     console.log("Socket data from processed event is 2nd step ",data);
  //     let state = {
  //       eta: data.route.time,
  //       mechanicAddress: data.route.start_address,
  //     };

  //     // console.log("State for Mechanic Route Details is ",state);
  //     setReRouteDetails(state);

  //     // This will get the ETA and current mechanic address
  //   } if (data.message === "Ticket location updated successfully") {
  //     // This fetches the OTP from ticket and mechanic Uid
  //     setScheduledArrivedOTP(data.ticket.scheduledArrivedOTP);
  //   }
  //   if (data.message === 'Route Calculated') {
  //     console.log("Start and End Address are:", {
  //       start: data.route.end_address,
  //       end: data.route.start_address
  //     });
  
  //     let directions = {
  //       status: true,
  //       origin: data.route.end_address,
  //       destination: data.route.start_address
  //     };
  //     setDirectionsDetails(directions);
  
  //     let state = {
  //       eta: data.route.time,
  //       mechanicAddress: data.route.start_address 
  //     };
  //     setReRouteDetails(state);
  //   }
  // });

  // useEffect(() =>{
  //    socket.emit("calculate-sp-route", {
  //   ticketId: "65f9577422665cfc0d1117fc",
  //   location: [18.532521, 73.829993],
  //   room: "mechanicTickets",
  // });
 
  // },[])


//   return (
// <div className="col-span-1 md:col-span-1 lg:col-span-1 xl:col-span-1 flex flex-col gap-4 h-full md:mb-72">
// <div className="mb-2 mt-2">
//   <p className="text-sm text-gray-600 font-bold">
//   Mechanic is on the way to workshop
//   </p>
// </div>
// <div className="flex items-center">
//   {/* Circular Time */}
//   <div className="mr-4">
//     <svg
//       xmlns="http://www.w3.org/2000/svg"
//       width="100"
//       height="100"
//       viewBox="0 0 120 120"
//     >
//       <defs>
//         <linearGradient id="grad1" x1="0%" y1="0%" x2="100%" y2="0%">
//           <stop
//             offset="0%"
//             style={{ stopColor: "#27BA48", stopOpacity: 1 }}
//           />
//           <stop
//             offset="100%"
//             style={{ stopColor: "#ccc", stopOpacity: 1 }}
//           />
//         </linearGradient>
//       </defs>
//       <circle
//         cx="60"
//         cy="60"
//         r="50"
//         fill="none"
//         stroke="url(#grad1)"
//         strokeWidth="10"
//       />
//       <text
//   x="50%"
//   y="40%"
//   textAnchor="middle"
//   dominantBaseline="middle"
//   fontSize="24"
//   fill="#000"
// >
//   {reRouteDetails ? Math.ceil(reRouteDetails.eta) : "20"}
// </text>

//       {/* Display the "min" label below the number */}
//       <text
//         x="50%"
//         y="59%"
//         textAnchor="middle"
//         dominantBaseline="middle"
//         fontSize="22"
//         fill="#000"
//       >
//         Min
//       </text>
//     </svg>
//   </div>
//   {/* Address */}
//   <div className="text-black">
//     <div className="flex">
//       <div className="ml-2">
//         <div className="flex mb-1 lg:items-center md:items-start items-center">
//           <svg
//             xmlns="http://www.w3.org/2000/svg"
//             viewBox="0 0 24 24"
//             width="16"
//             height="15"
//             className="text-black"
//             fill="none"
//             stroke="currentColor"
//             strokeWidth="2"
//             strokeLinecap="round"
//             strokeLinejoin="round"
//           >
//             <path d="M12 2C7.58 2 4 5.58 4 10c0 5.25 8 13 8 13s8-7.75 8-13c0-4.42-3.58-8-8-8z" />
//             <circle cx="12" cy="10" r="3" />
//           </svg>

//           <p className="font-bold ml-1 ">Mechanic Location</p>
//         </div>
//         <p className="text-sm">
//           {reRouteDetails?.mechanicAddress ||
//             "3/690, SS street, near St Thomas school, Delhi - 600345"}
//         </p>
//       </div>
//     </div>
//   </div>
// </div>
// </div>


//   );


return(
  <div>
  <div className="col-span-1 md:col-span-1 lg:col-span-1 xl:col-span-1 flex flex-col gap-4 h-full md:mb-64">
    <div className="mb-2 mt-2">
      <p className="text-3xl text-black font-semibold mt-6 md:mt-1">
        OTP: {scheduledArrivedOTP}
      </p>
      <p className="text-sm text-gray-600 font-bold">
        Mechanic is on the way to workshop
      </p>
    </div>
    <div className="flex items-center">
      {/* Circular Time */}
      <div className="mr-4">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="100"
          height="100"
          viewBox="0 0 120 120"
        >
          <defs>
            <linearGradient id="grad1" x1="0%" y1="0%" x2="100%" y2="0%">
              <stop
                offset="0%"
                style={{ stopColor: "#27BA48", stopOpacity: 1 }}
              />
              <stop
                offset="100%"
                style={{ stopColor: "#ccc", stopOpacity: 1 }}
              />
            </linearGradient>
          </defs>
          <circle
            cx="60"
            cy="60"
            r="50"
            fill="none"
            stroke="url(#grad1)"
            strokeWidth="10"
          />
           <text
  x="50%"
  y="40%"
  textAnchor="middle"
  dominantBaseline="middle"
  fontSize="24"
  fill="#000"
>
  {reRouteDetails ? Math.ceil(reRouteDetails.eta) : "20"}
</text>

          {/* Display the "min" label below the number */}
          <text
            x="50%"
            y="59%"
            textAnchor="middle"
            dominantBaseline="middle"
            fontSize="22"
            fill="#000"
          >
            Min
          </text>
        </svg>
      </div>
      {/* Address */}
      <div className="text-black">
        <div className="flex">
          <div className="ml-2">
            <div className="flex mb-1 lg:items-center md:items-start items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="16"
                height="15"
                className="text-black"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path d="M12 2C7.58 2 4 5.58 4 10c0 5.25 8 13 8 13s8-7.75 8-13c0-4.42-3.58-8-8-8z" />
                <circle cx="12" cy="10" r="3" />
              </svg>

              <p className="font-bold ml-1 ">Drop up Location</p>
            </div>
            <p className="text-sm">
              {reRouteDetails?.mechanicAddress ||
                "3/690, SS street, near St Thomas school, Delhi - 600345"}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
  </div>
)
};

export default Trackorderstep2;
