import React, { useState, useEffect } from 'react';
import ServiceDetails from '../components/ServiceDetails';
import ServiceCarousel from '../components/ServiceCarousel';
import serviceDetails1 from '../assets/ServiceDetails/ServiceDetailImage1.png';
import NavbarUpdate from '../components/NavbarUpdate';
import { useLocation } from 'react-router-dom';
import SERVER_URL from '../constants/constant';
import TrackPageNavbar from '../components/TrackPageNavbar';
import ServiceCarouselNew from '../components/ServiceCarouselNew';
import mixpanel from "../config/mixpanel";


// const serviceIncluded = [
//   {
//     subService: 'Oil Change',
//     subDescription: 'Replace engine oil with high-quality oil. Install a new oil filter.',
//   },
//   {
//     subService: 'Tire Rotation',
//     subDescription: 'Rotate tires to promote even wear.',
//   },
//   {
//     subService: 'Fluid Check',
//     subDescription: 'Inspect and top up essential fluids (brake, coolant, transmission).',
//   },
//   {
//     subService: 'Brake Inspection',
//     subDescription: 'Check brake pads and discs for wear. Ensure proper brake fluid levels.',
//   },
//   {
//     subService: 'Battery Check',
//     subDescription: 'Test battery performance and voltage.',
//   },
// ];

const ServiceDetailsScreen = () => {
  const [serviceData, setServiceData] = useState(null);
  const [modelData, setModelData] = useState(null);
  const [similarServices, setSimilarServices] = useState([]);
  const location = useLocation();
  const serviceId = location.state?.serviceId;
  console.log(serviceId);
  const modelId = JSON.parse(localStorage.getItem("vehicle-selected")).modelId;

 
  
  
  const fetchServiceData = async () => {
    try {
      
   
      // const response = await fetch(`${SERVER_URL}/service/getsingle?id=${serviceId}`);
      const response = await fetch(`${SERVER_URL}/service/getsingle?id=${serviceId}&modelId=${modelId}`);

     
      const data = await response.json();
      console.log("data in service detail screen is",data);


      const updatedServiceData = { ...data.service}; 
      const updatedModelData={...data.modelPrice}
      
      
      console.log("service details that we are passing",updatedServiceData);
      console.log("models details that we are passing",updatedModelData);
      
      setServiceData(updatedServiceData);
      setModelData(updatedModelData);
    } catch (error) {
      console.error('Error fetching service data:', error);
    }
  };
  
  useEffect(() => {
    fetchServiceData();
    mixpanel.track("ServiceDetail Page View", {
      page: window.location.pathname,
    });

    const hardcodedSimilarServices = [
      {
        title: 'Similar Service 1',
        imageUrl: serviceDetails1,
      },
      {
        title: 'Similar Service 2',
        imageUrl: serviceDetails1,
      },
      {
        title: 'Similar Service 3',
        imageUrl: serviceDetails1,
      },
    ];
    setSimilarServices(hardcodedSimilarServices);
  }, []);

  if (!serviceData) {
    return <p>Loading...</p>;
  }

  return (
    <div className='overflow-y-hidden mb-20'>
      <TrackPageNavbar showOnlyNav={false} />
      <div class="px-4 sm:px-6 md:px-8 lg:px-14 py-3 shadow-sm flex flex-col lg:flex-row gap-1 lg:gap-0 justify-between lg:items-center">
    <p class="text-sm text-gray-500 font-bold">
        <a href="/" class="hover:text-black hover:cursor-pointer">Home</a> 
        <span class="text-black"> / </span>
        <a href="/ServicePage" class="hover:text-black hover:cursor-pointer">Service</a> 
        <span class="text-black"> / </span>
        <span class="text-black">Detail</span>
    </p>
</div>
      <div className="mx-auto px-4 md:px-8 lg:px-14">
        {/* <div className="lg:flex flex-wrap">  */}
          <ServiceDetails service={serviceData}  model={modelData}/>
          
          {/* <ServiceCarousel similarServices={similarServices} /> */}
        {/* </div> */}
        <div>
          <p className='text-xl font-bold mb-2'>Similar Services</p>
          <ServiceCarouselNew />
        </div>
      </div>
    </div>
  );
};

export default ServiceDetailsScreen;
