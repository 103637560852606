
import React, { useState, useRef, useEffect } from "react";
import TrackPageNavbar from "../components/TrackPageNavbar";
import CancelIcon from "@mui/icons-material/Cancel";
import OopsIcon from "../assets/addVehicle/Oops.png";
// import PetrolIcon from "../assets/addVehicle/fuelType/Petrol.png";
// import DieselIcon from "../assets/addVehicle/fuelType/Diesel.png";
// import GasIcon from "../assets/addVehicle/fuelType/gas.png";
// import EVIcon from "../assets/addVehicle/fuelType/EV.png";
import { MdCloudUpload } from "react-icons/md";
// import SearchIcon from "@mui/icons-material/Search";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faSearch } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import carImage from "../assets/addVehicle/carImage.png";
import noImagelogo from "../assets/addVehicle/noImage.png";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-hot-toast";
import { Link, useNavigate } from "react-router-dom";
import SERVER_URL from "../constants/constant";



const Myvehicle = () => {
  const [showPopup, setShowPopup] = useState(true);
  const [showAddVehicleModal, setShowAddVehicleModal] = useState(false);
  const modalRef = useRef(null);
  const [addedVehicle, setAddedVehicle] = useState([]);
  // const [hasPermissionError, setHasPermissionError] = useState(false);
  const [userToken] = useState(localStorage.getItem("TOKEN"));
  const [editMode, setEditMode] = useState(false);
  // const [loading, setLoading] = useState(false);
  // const [error, setError] = useState(null);
  const [carNumberError, setCarNumberError] = useState("");

  // const [selectedBrand, setSelectedBrand] = useState("");

  const [brands, setBrands] = useState([]);
  const [models, setModels] = useState([]);
  const [fuelTypes, setFuelTypes] = useState([]);
  const [fuelSelected, setFuelSelected] = useState("");

  // console.log(localStorage.getItem("user"));


  const [vehicleData, setVehicleData] = useState({
    brandId: "",
    brandName: "",
    modelId: "",
    modelName: "",
    vehicleNo: "",
    fuelTypeId: "",
    fuelType:"",
  });

  useEffect(()=>{
    const storedData = JSON.parse(localStorage.getItem("vehicle-selected"));
    if(storedData && Object.values(storedData).every(value => value !== "")){
      setVehicleData(storedData);
      setAddedVehicle([storedData]);
      setShowPopup(false);
      setShowAddVehicleModal(false);
      console.log("intial storedData", storedData);
      console.log("intial AddedVehicle", addedVehicle);
    }        
      
  },[]);

  useEffect(() => {
    // Fetch list of brands when component mounts
    console.log("vehicle brand data processed");
    fetchBrands();
  }, []);

  useEffect(() => {
    console.log("Updated addedVehicle:", addedVehicle);
  }, [addedVehicle]);

  useEffect(() => {
    if (showAddVehicleModal && vehicleData) {
      // console.log("Vehicle Data on Modal Open:", vehicleData);
    }
  }, [showAddVehicleModal, vehicleData]);


  const fetchBrands = async () => {
    try {
      const apiEndpoint =
        "https://service-provider-apis.onrender.com/api/v1//brand/getAll";
      const response = await axios.get(apiEndpoint);
      console.log("response data", response);
      setBrands(response.data.data);
    } catch (error) {
      console.error("Error fetching brands:", error);
    }
  };

  const deleteVehicle = async (vehicleId) => {
    try {
      const apiEndpoint = `https://service-provider-apis.onrender.com/api/v1/vehicle/${vehicleId}`;
      const headers = {
        Authorization: `Bearer ${userToken}`,
      };


      // const headers = {
      //   "Content-Type": "application/json",
      //   Authorization: `Bearer ${userToken}`,
      // };

      const response = await axios.delete(apiEndpoint, { headers });

      if (response.data.success) {
        // Remove the deleted vehicle from the state
        setAddedVehicle([]);
        // setAddedVehicle((prevAddedVehicle) => prevAddedVehicle.filter((vehicle) => vehicle._id !== vehicleId))
        const storedVehicles = JSON.parse(localStorage.getItem("vehicle-selected"));
        if (storedVehicles) {
          localStorage.removeItem("vehicle-selected");
        }
        toast.success(response.data.message);
      } else {
        toast.success(response.data.message);
        console.error("Failed to delete vehicle");
      }
    } catch (error) {
      console.error("Error deleting vehicle:", error);
      toast.error("An error occurred while deleting the vehicle");
    }
  };

  const updateVehicle = async (vehicleId, updatedData) => {
    // setLoading(true);
    // setError(null);
  
    try {
      const apiEndpoint = `https://service-provider-apis.onrender.com/api/v1/vehicle/${vehicleId}`;
      const headers = {
        Authorization: `Bearer ${userToken}`,
      };

      const response = await axios.put(apiEndpoint, updatedData, { headers });

      if (response.data.success) {
        // Update the vehicle data in the state
        console.log("updatevehicle response", response)
        setAddedVehicle((prevVehicles) =>
          prevVehicles.map((vehicle) => {
            if (vehicle._id === response.data.vehicle._id) {
              return {
                ...vehicle, 
                ...response.data.vehicle, 
              };
            }
            return vehicle; 
          })
        );
        toast.success("vehicle updated successfully");
        setShowPopup(false)
      } 
      else {
        toast.error(response.data.message);
        console.error("response not true");
      }
    } 
  
    catch (error) {
      toast.error("Error updating vehicle:");
      console.error("Error updating vehicle:", error);
      // setError("An error occurred while updating the vehicle");
    }
    finally {
      // setLoading(false);
    }
  };
  
  console.log("After updating in updateVehicle", addedVehicle)

  const handleEditSubmit = () => {
    // Call the update API with the updated vehicle data
    console.log("addedVehicleID", addedVehicle[0]._id, addedVehicle);
    updateVehicle(addedVehicle[0]._id, vehicleData);
  };

  const getModels = async (brand_id) => {
    try {
      const response = await fetch(`${SERVER_URL}/model/consumer/get-by-brand?brandId=${brand_id}`);

      if (!response.ok) {
        throw new Error(`Network response was not ok: ${response.status}`);
      }
      const result = await response.json();
      const modelData = result?.models;

      setModels(modelData);

    }
    catch (error) {
      // If there was an error, set the error state
      console.log(error.message);
    }
  }

  const handleInputChange = async (field, value) => {
    if (field === "brandName") {
      const selectedBrand = brands.find((brand) => brand.name === value);

      if (selectedBrand) {
        // Wait for getModels to finish before updating the state
        await getModels(selectedBrand._id);
        console.log("models after fetching:", models);
      }

      setVehicleData((prevData) => ({
        ...prevData,
        brandId: selectedBrand ? selectedBrand._id : "",
        brandName: value,
        modelName: "",
        modelId: "",
        fuelType: "",
      }));
    } else if (field === "modelName") {
      const selectedModel = models.find((model) => model.name === value);

      if (selectedModel) {
        setFuelTypes(selectedModel.fuelType);
        console.log("Setting fuel types:", selectedModel.fuelType);
      }

      setVehicleData((prevData) => ({
        ...prevData,
        modelName: value,
        modelId: selectedModel ? selectedModel._id : "",
        fuelType: "",
      }));
    } else if (field === "fuelType") {
      console.log("fuelTypes:", fuelTypes, "value:", value);
      const selectedFuel = fuelTypes.find((fuel) => fuel.fuelType === value);

      const fuelId = selectedFuel ? selectedFuel._id : "";
      console.log("Fuel ID:", fuelId);

      setFuelSelected(value);

      setVehicleData((prevData) => ({
        ...prevData,
        fuelType: value,
        fuelTypeId: fuelId,
      }));
    } else {
      setVehicleData((prevData) => ({
        ...prevData,
        [field]: value,
      }));
    }
  };


  if (vehicleData) {
    console.log("Selected vehicleData:", vehicleData);
  } else {
    console.log("No fuel type selected.");
  }

  const closePopup = () => {
    setShowPopup(false);
  };

  const closeAddVehicleModal = () => {
    setShowPopup(!showPopup);
    setShowAddVehicleModal(!showAddVehicleModal);
  };

  const handleLicenceUpload = (e) => {
    // const file = e.target.files[0];
    // const licenceUrl = URL.createObjectURL(file);

    // setUserDetails({
    //   ...userDetails,
    //   licenceImg: licenceUrl,
    // });
    // console.log(userDetails);
  };

  useEffect(() => {
    // Fetch the single added vehicle when the component mounts
    const fetchAddedVehicle = async () => {
      try {
        const apiEndpoint =
          "https://service-provider-apis.onrender.com/api/v1/vehicle/65f54491b0119544cb30ad6b";
        const headers = {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        };

        const response = await axios.get(apiEndpoint, { headers });

        if (response.data.success) {
          setAddedVehicle(response.data.vehicle);
        } else {
          // setHasPermissionError(true);
        }
      } catch (error) {
        console.error("Error fetching added vehicle:", error);
      }
    };

    fetchAddedVehicle();
  }, [userToken]);


  const addVehicleToApi = () => {
    const apiEndpoint =
      "https://service-provider-apis.onrender.com/api/v1/vehicle/create";
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${userToken}`,
    };

    console.log("vehicleeeeeeeeee dataaaaaaaaaaaaa", vehicleData)

    axios
      .post(apiEndpoint, vehicleData, { headers })
      .then((response) => {
        console.log("API Response:", response.data);

        if (response.data.success) {
          console.log("success response", response.data.success);
          console.log("this is response", response.data);

          const updatedVehicle = {
            ...response.data.vehicle,
            fuelType: fuelSelected, // Add the fuelType key
          };
          
          localStorage.setItem('vehicle-selected', JSON.stringify(updatedVehicle));
          setAddedVehicle([updatedVehicle]);
          
          
          // toast.success("Vehicle Created Successfully");
        } else {
          toast.error("erroe msg", response.data.message);
        }

        setShowPopup(false);
        setShowAddVehicleModal(false);
        // localStorage.setItem('vehicle-selected', JSON.stringify(vehicleData));
      })
      .catch((error) => {
        console.error("API Error:", error);

        // Checking if the error has a response property
        if (error.response) {
          console.error("Error Response:", error.response.data);
          // showDetails(); 
        }
        else if (error.request) {
          console.error("Error Request:", error.request);
        }
        else {
          console.error("Error Message:", error.message);
        }

        setShowPopup(false); // Ensure state is updated even on error
        setShowAddVehicleModal(false); // Ensure state is updated even on error
      });

  };



  const handleAddVehicle = () => {
    addVehicleToApi();
  };

  const handleSubmit = () => {
    if (editMode) {
      handleEditSubmit();
    } else {
      addVehicleToApi();
    }
    setShowAddVehicleModal(false);
  };

  // Function to open the modal in add mode
  const openAddVehicleModal = () => {
    setShowPopup(!showPopup);
    setEditMode(false);
    setShowAddVehicleModal(true);

    setVehicleData({
      brandId: "",
      brandName: "",
      modelId: "",
      modelName: "",
      vehicleNo: "",
      modelYear: "",
      RCname: "",
      RCnumber: "",
      RCScan: "default_rc_scan.jpg",
    });
  };

  // Function to open the modal in edit mode

  const openEditVehicleModal = () => {
    setShowPopup(!showPopup);
    setEditMode(true); // Set edit mode to true
    setShowAddVehicleModal(true);

    // updateVehicle(addedVehicle._id, vehicleData);

    console.log("Added Vehicle in openedit modal:", addedVehicle);

    if (addedVehicle) {
      setVehicleData({
        ...addedVehicle[0],
      });
    }
    console.log("vehicle Data in openedit modal", vehicleData);
  };



  return (
    <>
      <TrackPageNavbar showOnlyNav={false} />

      <div class="px-4 sm:px-6 md:px-8 lg:px-14 py-3 shadow-sm flex flex-col lg:flex-row gap-1 lg:gap-0 justify-between lg:items-center">
        <p class="text-sm text-gray-500 font-bold">
          <a href="/" class="hover:text-black hover:cursor-pointer">Profile</a>
          <span class="text-black"> / </span>
          <span class="text-black">My Vehicle</span>

        </p>
      </div>

      {addedVehicle.length > 0 ? (
        <div className="flex flex-col sm:flex-row sm:gap-2 px-4 font-poppins">
          {addedVehicle.map(vehicle => (
            <div key={vehicle._id} className="border p-4 rounded-md mb-4 sm:w-full md:w-3/4 lg:w-3/4 xl:w-1/3">
              <div className="flex flex-col sm:flex-row w-full justify-between gap-3 mb-2">
                <div className="flex flex-col w-full sm:w-3/5 justify-between">
                  <div className="flex justify-between w-full">
                    <div>
                      <p className="text-gray-500">Car Brand:</p>
                      <p className="font-semibold">{vehicle.brandName}</p>
                    </div>
                    <div>
                      <p className="text-gray-500">Car Model:</p>
                      <p className="font-semibold">{vehicle.modelName}</p>
                    </div>
                  </div>

                  <div className="flex justify-between w-full">
                    <div>
                      <p className="text-gray-500">Fuel:</p>
                      <p className="font-semibold">{vehicle.fuelType}</p>
                    </div>
                    <div>
                      <p className="text-gray-500">Car Number:</p>
                      <p className="font-semibold">{vehicle.vehicleNo}</p>
                    </div>
                  </div>
                </div>

                <div className="flex w-full sm:w-2/5 mt-4 sm:mt-0 justify-center">
                  <img alt="" src={carImage} className="h-auto rounded-md" />
                </div>
              </div>

              {/* <div className="mb-2">
                <p className="text-gray-500">Model Year: </p>
                <p className="font-semibold">{vehicle.modelYear}</p>
              </div> */}

              {/* License Detail */}
              {/* <div className="flex w-full sm:w-2/3 justify-between items-center mb-2">
                <div className="w-full sm:w-1/2">
                  <h1 className="text-lg">License Detail</h1>
                  <div className="font-semibold">
                    <p>1234567</p>
                    <p>Name</p>
                  </div>
                </div>

                <div className="border-2 flex border-gray- rounded w-full sm:w-1/2 py-5 justify-center">
                  <img alt="noImage" src={noImagelogo} className="items-center" />
                </div>
              </div> */}

              {/* RC Detail */}
              <div className="flex w-full justify-between items-center flex-col md:flex-row mb-2">
                {/* <div className="flex w-full sm:w-2/3 justify-between items-center mb-2">
                  <div className="w-full sm:w-1/2">
                    <h1 className="text-lg">RC Detail</h1>
                    <div className="font-semibold">
                      <p>{vehicle.RCnumber}</p>
                      <p>{vehicle.RCname}</p>
                    </div>
                  </div>
                  <div className="border-2 flex border-gray- rounded w-full sm:w-1/2 py-5 justify-center">
                    <img alt="noImage" src={noImagelogo} className="items-center" />
                  </div>
                </div> */}

                <div className="flex flex-col gap-2 w-full sm:w-full md:w-1/2 lg:w-1/3 items-center">
                  <div className="flex items-center gap-2 cursor-pointer" onClick={openEditVehicleModal}>
                    <span style={{ color: "#22C55D" }}>
                      <FontAwesomeIcon icon={faEdit} />
                    </span>
                    <span className="text-lg font-semibold" style={{ color: "#22C55D" }}>
                      Edit
                    </span>
                  </div>
                  <div className="flex items-center gap-2 cursor-pointer" onClick={() => deleteVehicle(vehicle._id)}>
                    <span style={{ color: "red" }}>
                      <FontAwesomeIcon icon={faTrashAlt} />
                    </span>
                    <span className="text-lg font-semibold" style={{ color: "red" }}>
                      Delete
                    </span>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <button
                onClick={openAddVehicleModal}
                style={{ background: "#22C55D", color: "#fff" }}
                className="py-2 px-4 rounded md:my-0 my-2 mx-5"
              >
                Add Vehicle
              </button>
      )}


      {showPopup && addedVehicle.length==0 && (
        <div
          style={{ background: "#212121" }}
          className="w-full h-screen fixed top-0 right-0 left-0 grid place-items-center"
          onClick={(e) => {
            if (modalRef.current && !modalRef.current.contains(e.target)) {
              setShowPopup(false);
            }
          }}
        >
          <div
            className="bg-white w-11/12 sm:w-3/4 lg:w-1/3 py-2 px-4 rounded flex flex-col items-center"
            ref={modalRef}
          >
            <div className="flex justify-between w-full">
              <CancelIcon style={{ cursor: "pointer" }} onClick={closePopup} />
              <span className="text-lg font-bold mx-auto">My vehicle</span>
            </div>
            <div className="flex text-center flex-col items-center justify-center">
              <img alt="" src={OopsIcon} className="mt-4" />
              <p className="my-3">
                No result for your old vehicle. Add a new vehicle and Explore
                compatible parts!
              </p>
            </div>
            <div className="flex md:flex-row flex-col items-center justify-center">
              <button
                onClick={openAddVehicleModal}
                style={{ background: "#22C55D", color: "#fff" }}
                className="py-2 px-4 rounded md:my-0 my-2"
              >
                Add new vehicle
              </button>
            </div>
          </div>
        </div>
      )}
      {showAddVehicleModal && (
        <div
          style={{ background: "rgba(33, 33, 33, 0.8)" }}
          className="w-full h-screen fixed top-0 right-0 left-0 grid place-items-center"
          onClick={(e) => {
            if (modalRef.current && !modalRef.current.contains(e.target)) {
              closeAddVehicleModal();
            }
          }}
        >
          <div
            className="bg-white w-11/12 sm:w-3/4 lg:w-1/2 py-2 px-4 rounded overflow-y-auto "
            ref={modalRef}
            style={{ maxHeight: "70vh", scrollbarColor: "#22C55D #fff" }}
          >
            <h1 className="text-lg font-bold text-center sticky top-0 z-20">
              Add Vehicle
            </h1>
            <div className="p-3 mt-4">
              <div className="flex flex-col">
                <div className="my-2">
                  <label
                    htmlFor="brand"
                    className="block text-black font-semibold"
                  >
                    Brand:
                  </label>
                  <div className="relative">
                    <select
                      id="brand"
                      value={vehicleData.brandName}
                      onChange={(e) =>

                        handleInputChange("brandName", e.target.value)
                      }
                      className="pl-2 border-2 border-gray-300 p-2 rounded-md w-full outline-none"
                    >
                      <option value="">Select Car Brand</option>
                      {brands.map((brand) => (
                        <option key={brand._id} value={brand.name}>
                          {brand.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="my-2">
                  <label
                    htmlFor="model"
                    className="block text-black font-semibold"
                  >
                    Model:
                  </label>
                  <div className="relative">
                    <select
                      id="model"
                      value={vehicleData.modelName}
                      onChange={(e) => {
                        console.log("ee is ", e);
                        handleInputChange("modelName", e.target.value)
                      }
                      }
                      className="pl-2 border-2 border-gray-300 p-2 rounded-md w-full outline-none"
                    >
                      <option value="">Select Car Model</option>
                      {models.map((model) => (
                        <option key={model._id} value={model.name}>
                          {model.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="my-2 w-full">
                  <label
                    htmlFor="fuel"
                    className="block text-black font-semibold"
                  >
                    Fuel:
                  </label>
                  <select
                    id="fuelType"
                    value={vehicleData.fuelType}
                    onChange={(e) => {
                      console.log("ee is ", e);
                      handleInputChange("fuelType", e.target.value)
                    }
                    }
                    className="pl-2 border-2 border-gray-300 p-2 rounded-md w-full outline-none"
                  >
                    <option value="">Select Car Fuel Type</option>
                    {fuelTypes.map((fuel) => (
                      <option key={fuel._id} value={fuel.fuelType}>
                        {fuel.fuelType}
                      </option>
                    ))}
                  </select>

                  {/* <div className="flex w-full justify-between gap-2 cursor-pointer mt-2">
                          {
                                fuelTypes.map((fuel) => (
                        <div
                          key={fuel._id}
                          
                          className={`flex flex-col items-center border-2 border-gray-300 p-4 rounded-md group hover:border-b-4 hover:border-b-green-500 w-1/4 ${
                            vehicleData.fuelType === fuel.fuelType
                              ? "border-b-4 border-green-500"
                              : ""
                          }`}
                          onClick={() =>
                            handleInputChange("fuelType", fuel.fuelType)
                          }
                        >
                          <img src={fuel.icon} alt={fuel.fuelType} />
                          <h1 className="text-center">{fuel.fuelType}</h1>
                        </div>
                      ))} 
                        
                  </div> */}




                </div>
              </div>

              {/* <div className="flex w-full justify-between gap-2">
                    {/* {fuelTypes &&
                      fuelTypes.map((fuel) => (
                        <div
                          key={fuel._id}
                          
                          className={`flex flex-col items-center border-2 border-gray-300 p-4 rounded-md group hover:border-b-4 hover:border-b-green-500 w-1/4 ${
                            vehicleData.fuelType === fuel.fuelType
                              ? "border-b-4 border-green-500"
                              : ""
                          }`}
                          onClick={() =>
                            handleInputChange("fuelType", fuel.fuelType)
                          }
                        >
                          <img src={fuel.icon} alt={fuel.fuelType} />
                          <h1 className="text-center">{fuel.fuelType}</h1>
                        </div>
                      ))}  */}

              {/* {fuelTypes.map((fuel) => (
                         <div key={fuel._id}>
                            <img src={fuel.icon} alt={fuel.fuelType} />
                             <p>{fuel.fuelType}</p>
                          </div>
                          
                         ))} */}
              {/* </div> */}


              <div className="flex justify-between gap-2">
                <div className="my-2 w-1/2">
                  <label
                    htmlFor="brand"
                    className="block text-black font-semibold"
                  >
                    Car Number:
                  </label>
                  <input
                    type="text"
                    id="brand"
                    placeholder=" (e.g., XX00XX0000)"
                    className="border-2 border-gray-300 p-2 rounded-md w-full outline-none"
                    onChange={(e) => {
                      const carNumber = e.target.value;
                      handleInputChange("vehicleNo", carNumber);
                      const regex =
                        /^[A-Za-z]{2}\s?[0-9]{2}\s?[A-Za-z]{1,2}\s?[0-9]{4}$/;
                      const isValid = regex.test(carNumber);
                      setCarNumberError(
                        isValid
                          ? ""
                          : "Please enter a valid Indian vehicle number"
                      );
                    }}
                    required
                  />
                  {carNumberError && (
                    <p className="text-red-500 text-sm mt-1">
                      {carNumberError}
                    </p>
                  )}
                </div>

                {/* <div className="my-2 w-1/2 ">
                  <label
                    htmlFor="brand"
                    className="block text-black font-semibold"
                  >
                    Model Year:
                  </label>
                  <input
                    type="text"
                    id="brand"
                    // placeholder="Enter brand"
                    className="border-2 border-gray-300 p-2 rounded-md w-full outline-none "
                    onChange={(e) =>
                      handleInputChange("modelYear", e.target.value)
                    }
                  />
                </div> */}
              </div>

              {/* <div className="w-full mt-4  mb-14 " style={{ height: "100px" }}>
                <label className="text-black font-bold ">Licence Details</label>

                <div className="flex flex-row mt-4 mb-4 justify-start items-center ">
                  <label className="text-black w-1/3 ">Licence Number:</label>
                  <input
                    type="number"
                    placeholder="eg-00234567"
                    className=" border-b-2 border-gray-300 rounded-md p-2 w-1/2 outline-none"
                  />
                </div>

                <div className="flex flex-row mb-4 justify-start items-center ">
                  <label className="text-black w-1/3">Licence Name:</label>
                  <input
                    type="text"
                    placeholder="eg-00234567"
                    className=" border-b-2 border-b-gray-300 rounded-md p-2 w-1/2 outline-none"
                  />
                </div>
              </div> */}

              {/* <div className="w-full p-4  mb-4">
                <label htmlFor="licence">
                  <div className=" py-6 shadow-[rgba(0,_0,_0,_0.24)_0px_3px_8px] flex flex-col justify-middle align-center text-center cursor-pointer">
                    <div className="flex justify-center">
                      <MdCloudUpload
                        size={40}
                        className="w-10 h-10 text-[#5A5A5A]"
                      />
                    </div>
                    <p className="text-[#5A5A5A]">
                      Upload a scanned copy of your licence
                    </p>
                    <p className="text-[#22C55D] text-xl font-semibold">
                      Upload File
                    </p>
                  </div>
                </label>
                <input
                  type="file"
                  id="licence"
                  accept="image/*, application/*"
                  className="hidden"
                  onChange={handleLicenceUpload}
                />
              </div> */}

              {/* <div className="w-full mt-4  mb-14 " style={{ height: "100px" }}>
                <label className="text-black font-bold ">RC Details</label>

                <div className="flex flex-row mt-4 mb-4 justify-start items-center ">
                  <label className="text-black w-1/3 ">RC Number:</label>
                  <input
                    type="number"
                    placeholder="eg-00234567"
                    className=" border-b-2 border-b-gray-300 rounded-md p-2 w-1/2 outline-none"
                    onChange={(e) =>
                      handleInputChange("RCnumber", e.target.value)
                    }
                  />
                </div> */}

                {/* <div className="flex flex-row mb-4 justify-start items-center ">
                  <label className="text-black w-1/3">RC Name:</label>
                  <input
                    type="text"
                    placeholder="eg-00234567"
                    className=" border-b-2 border-b-gray-300 rounded-md p-2 w-1/2 outline-none"
                    onChange={(e) =>
                      handleInputChange("RCname", e.target.value)
                    }
                  />
                </div>
              </div> */}

              {/* <div className="w-full p-4  mb-4">
                <label htmlFor="licence">
                  <div className=" py-6 shadow-[rgba(0,_0,_0,_0.24)_0px_3px_8px] flex flex-col justify-middle align-center text-center cursor-pointer">
                    <div className="flex justify-center">
                      <MdCloudUpload
                        size={40}
                        className="w-10 h-10 text-[#5A5A5A]"
                      />
                    </div>
                    <p className="text-[#5A5A5A]">
                      Upload a scanned copy of your RC
                    </p>
                    <p className="text-[#22C55D] text-xl font-semibold">
                      Upload File
                    </p>
                  </div>
                </label>
                <input
                  type="file"
                  id="licence"
                  accept="image/*, application/*"
                  className="hidden"
                  onChange={handleLicenceUpload}
                />
              </div> */}
            </div>
            <div className="flex md:flex-row flex-col items-center justify-end mb-4">
              <a
                href="#"
                onClick={closeAddVehicleModal}
                className="text-green-500 font-semibold p-2 mr-3 cursor-pointer"
              >
                cancel
              </a>
              <button
                onClick={handleSubmit}
                style={{ background: "#22C55D", color: "#fff" }}
                className="py-2 px-4 rounded md:my-0 my-2"
              >
                {editMode ? "Save Changes" : "Add Vehicle"}
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Myvehicle;

