import React, { forwardRef, useState } from "react";
//import all from '../data/Vehicle';
import VehicleIcon from "./VehicleIcon";
import { AiOutlineArrowLeft } from "react-icons/ai";
import TextField from "@mui/material/TextField";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import axios from "axios";
import SERVER_URL from "../constants/constant";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-hot-toast";

const VehicleSelector = forwardRef(({ className='', carno=false, ...props }, ref) => {
  const [userToken] = useState(localStorage.getItem("TOKEN"));
  const [brandSearch, setBrandSearch] = useState("");
  const [isHovered, setIsHovered] = useState(false);

  const [copyData, setCopyData] = useState({});

  const [loading, setLoading] = useState(false);

  const [selectionDone, setSelectionDone] = useState(false);

  const [brandSelected, setBrandSelected] = useState(null);
  const [modelSelected, setModelSelected] = useState([]);
  const [modelList, setmodelList] = useState([]);
  const [fuellist, setFuellist]=useState([]);
  // console.log("MS",modelSelected);
  const [fuelSelected, setFuelSelected] = useState(null);
  const [carnum, setCarNumber] = useState("");
  const [vehNumber, setVehNumber] = useState("");
  const [debouncedCarnum, setDebouncedCarnum] = useState('');


  // const [vehicleData, setVehicleData] = useState(
  //   JSON.parse(localStorage.getItem("vehicle-selected"))
  //     ? JSON.parse(localStorage.getItem("vehicle-selected"))
  //     : []
  // );

  useEffect(() => {
    console.log("Updated modelSelected:", modelSelected);
    const ans = Array.isArray(modelSelected) && modelSelected.length > 0;
    console.log("isarray",ans )
  }, [modelSelected]);

  useEffect(() => {
    console.log("modelList:", modelList);
  }, [modelList]);

  useEffect(() => {
    console.log("updated copy Data", copyData);
    console.log("brand selected is", brandSelected);
    console.log("model selected iss", modelSelected);
    if (copyData && Object.values(copyData).every(value => value !== "") && (!carno || (carno && copyData.vehicleNo !== ""))){
        setSelectionDone(true);
        setMenu(false);
        setLoading(true);
    }
  }, [copyData]);

  const [vehicleData, setVehicleData] = useState( ()=> {
    const storedData = JSON.parse(localStorage.getItem("vehicle-selected"));

    return storedData || {
      brandId: "",
      brandName: "",
      modelId: "",
      modelName: "",
      fuelTypeId: "",
      fuelType: "",   
      vehicleNo: ""
    };
  });


  const [brands, setBrands] = useState(() => {
    try {
      const storedBrands = localStorage.getItem("user-brands");
      if (storedBrands) {
        return JSON.parse(storedBrands);
      }
    } catch (error) {
      console.error("Error parsing stored brands:", error);
    }
    return []; // Fallback to empty array if there's an error
  });

  
  useEffect(() => { 
    const storedData = JSON.parse(localStorage.getItem("vehicle-selected"));
  
    if (storedData && Object.values(storedData).every(value => value !== "")) {
      setVehicleData(storedData); // Sets the `vehicleData` state with storedData
      setFuelSelected(storedData.fuelType);
      setVehNumber(storedData.vehicleNo);
      
      // Filter the selected brand from stored brands
      const filteredBrand = brands.filter((brand) => brand.name === storedData.brandName);
      if(filteredBrand && filteredBrand.length>0){
        setBrandSelected(filteredBrand[0]);
        if (filteredBrand.length > 0) {
        setCopyData({
          brand: filteredBrand[0], // Sets the selected brand
          model: "", // Placeholder for model
          fuel: "", // Placeholder for fuel
          vehicleNo: "", // Placeholder for vehicle number
        });
        // Fetches models after the brand is selected
        getModel(filteredBrand[0]);
        console.log("filtered brand", filteredBrand)
      }
      
      }
    }
  }, [brands]);
  
  
  useEffect(() => {
    if (modelSelected && Array.isArray(modelSelected) && modelSelected.length > 0) {
      const storedData = JSON.parse(localStorage.getItem("vehicle-selected"));
      
      if (storedData) {
        const filteredModel = modelSelected.filter((mod) => mod.name === storedData.modelName);
        if(filteredModel && filteredModel.length>0){
          setModelSelected(filteredModel[0]);

          setCopyData((prevdata) => ({
          ...prevdata,
          model: filteredModel[0], // Updates model in `copyData`
          fuel: storedData.fuelType,
          vehicleNo: storedData.vehicleNo,
        }));
      
        console.log("Model selected:", modelSelected);
        console.log("FilteredModel", filteredModel);
        }
        
      }
    }
  }, [modelSelected]);
  

  useEffect(() => {
    // Ensure vehicleData is an object and not null
    if (vehicleData && vehicleData.brandName!="" && vehicleData.modelName!="" && vehicleData.fuelType!="") {
 
        localStorage.setItem("vehicle-selected", JSON.stringify(vehicleData));
        console.log("Vehicle data saved to local storage:", vehicleData);
    }

    else {
      console.log("Invalid vehicle data; local storage cleared.");
    }
  }, [vehicleData]);



  // const [brands, setBrands] = useState(() => {
  //   const storedBrands = localStorage.getItem("user-brands");
  //   return storedBrands ? JSON.parse(storedBrands) : [];
  // });
  
  // const [brands,setBrands]=useState([]);
  // const [brands, setBrands] = useState(
  //   JSON.parse(localStorage.getItem("user-brands"))
  //     ? JSON.parse(localStorage.getItem("user-brands"))
  //     : []
  // );

  const [vehicles, setVehicles] = useState(
    JSON.parse(localStorage.getItem("user-vehicles"))
      ? JSON.parse(localStorage.getItem("user-vehicles"))
      : []
  );

  const [fuels, setFuels] = useState(
    JSON.parse(localStorage.getItem("user-vehicles-fuels"))
      ? JSON.parse(localStorage.getItem("user-vehicles-fuels"))
      : []
  );

  useEffect(() => {
    console.log("Updated vehicleDatas: ", vehicleData);
  }, [vehicleData]); 


  useEffect(() => {
    if (brands.length < 1) {
      setLoading(true);
    } else {
      setLoading(false);
    }
    // setLoading(true);
    
  }, [brands]);

  // console.log(loading);

  useEffect(() => {
    fetch(`${SERVER_URL}/brand/getAll`)
      .then((response) => {
       
        if (!response.ok) {
          throw new Error(`Network response was not ok: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        console.log("brrrrrrr ",data);
        const initialData = data?.data;
        console.log("brand base",data);
        // console.log(initialData,"iniiiiii");
        localStorage.setItem("user-brands",JSON.stringify(initialData));
        
        setBrands(initialData);
       
      })
      .catch((error) => console.error(error));

    // fetch(`${SERVER_URL}/model/getAll`)
    //   .then((response) => {
    //     if (!response.ok) {
    //       throw new Error(`Network response was not ok: ${response.status}`);
    //     }
    //     return response.json();
    //   })
    //   .then((data) => {
        
    //     const initialData = data?.models;
    //     // console.log(initialData);
       
    //     console.log("model ",data);
    //     localStorage.setItem("user-vehicles", JSON.stringify(initialData));
    //     setVehicles(initialData);
        
    //   })
    //   .catch((error) => console.error(error));
  }, []);

  // useEffect(() => {
  //   const data = JSON.parse(localStorage.getItem("vehicle-selected"));

    
      
  //   if (data && data.brand) {
  //     setVehicleData(data);
  //     setCopyData(data);
  //     setSelectionDone(true);
  //     setMenu(false);
  //   }
  // }, []);

  // Debounce the carnum value
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedCarnum(carnum);
    }, 1000); // Waits for 1 second after the last input

    return () => {
      clearTimeout(handler);
    };
  }, [carnum]);

  // Trigger the save action when the debounced value changes
  useEffect(() => {
    if (debouncedCarnum) {
      handleVehicleNoClick(debouncedCarnum);
    }
  }, [debouncedCarnum]);

  useEffect(() => {
    if(vehNumber!=""){
      addVehicleToApi();
    }
  }, [vehNumber]);

  const addVehicleToApi = () => {
    const apiEndpoint =
      "https://service-provider-apis.onrender.com/api/v1/vehicle/create";
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${userToken}`,
    };

    console.log("addvehicletoapi run")
    console.log("vehicleeeeeeeeee dataaaaaaaaaaaaa selector", vehicleData)

    axios
      .post(apiEndpoint, vehicleData, { headers })
      .then((response) => {
        console.log("API Response:", response.data);

        if (response.data.success) {
          console.log("success response", response.data.success);
          console.log("this is response", response.data);

          const updatedVehicle = {
            ...response.data.vehicle,
            fuelType: fuelSelected, // Add the fuelType key
          };
          
          localStorage.setItem('vehicle-selected', JSON.stringify(updatedVehicle));
          
          
          // toast.success("Vehicle Created Successfully");
        } else {
          toast.error("error msg", response.data.message);
        }

      })
      .catch((error) => {
        console.error("API Error:", error);

        // Checking if the error has a response property
        if (error.response) {
          console.error("Error Response:", error.response.data);
          // showDetails(); 
        }
        else if (error.request) {
          console.error("Error Request:", error.request);
        }
        else {
          console.error("Error Message:", error.message);
        }

      });

  };

  const getModel= async (brand)=>{
    const brand_id=brand._id;
    try{
      const response=await fetch(`${SERVER_URL}/model/consumer/get-by-brand?brandId=${brand_id}`);

      if (!response.ok) {
        throw new Error(`Network response was not ok: ${response.status}`);
      }
      const result = await response.json();
      const modelData=result?.models;
      
      setModelSelected(modelData);
      setmodelList(modelData);
      console.log("setmode ",modelData);
      console.log(result,"models new is there ");
    }
    catch (error) {
      // If there was an error, set the error state
      console.log(error.message);
  }
}



  const [placeholder, setPlaceholder] = useState([
    "Search Brand",
    "Search Model",
    "Search Fuel",
  ]);

  const [count, setCount] = useState(0);

  const [menu, setMenu] = useState(true);

  function handleBackPress() {
    setBrandSearch("");
   
    if (count === 0) 
      {
        
        setMenu(false);
      }
    if (count > 0) 
      {
        if(count===2)
        {
          handleBrandClick(brandSelected);
          setCount(1);
        }
        else
        setCount(count-1);
        
      }
  }

  const handleBrandClick = (brand) => {
    console.log("brand clecked is ", brand);
    getModel(brand);
    setBrandSearch("");
    setBrandSelected(brand);
    setFuelSelected(null);
    setCount(count + 1);


    // await getModels(selectedBrand._id);

    setVehicleData((prevData) => ({
      ...prevData,
      brandId: brand._id,
      brandName: brand.name,
      modelName: "",
      modelId: "",
      fuelType: "",
      vehicleNo:"",
    }));
  };

  const handleModelClick = (model) => {
    console.log("model clicked fine");
    setBrandSearch("");
    console.log("model selected is ",model);
    const fuellists=model.fuelType;
    setFuellist(fuellists);
    setModelSelected(model);
    setCount(count + 1);
    
      setVehicleData((prevData) => ({
        ...prevData,
        modelName: model.name,
        modelId: model._id,
        fuelType: "",
        vehicleNo:"",
      }));
  };

  const handleFuelClick = (fuel) => {
    setFuelSelected(fuel);
    setCount(count+1);
    setSelectionDone(false);
    {!carno && (() => {
      setSelectionDone(true);
      setMenu(false);
      setCount(0);
    })()}
    

      const selectedFuel = fuellist.find((f) => f.fuelType === fuel);

      const fuelId = selectedFuel ? selectedFuel._id : "";
      console.log("Fuel ID:", fuelId, "and fuel is ", fuel);

      setVehicleData((prevData) => ({
        ...prevData,
        fuelType: fuel,
        fuelTypeId: fuelId,
        vehicleNo:"",
      }));
  };

  const handleVehicleNoClick = (e) => {
    setVehNumber(e);
    setSelectionDone(true);
    setMenu(false);
    setCount(0);
    setCarNumber(e);
    setCopyData({
      brand: brandSelected,
      model: modelSelected,
      fuel: fuelSelected,
      vehicleNo:e
    });

    console.log("copy ", copyData);

      setVehicleData((prevData) => ({
        ...prevData,
        vehicleNo:e
      }));

      setCarNumber("");
  };

  


  // console.log("brands", brandSelected?.models);
  const handleRemoveSelection = () => {
    setBrandSelected(null);
    setModelSelected(null);
    setFuelSelected(null);
    setSelectionDone(false);
    setCopyData({});
    setCount(0);
    setVehicleData({
      brandId: "",
      brandName: "",
      modelId: "",
      modelName: "",
      fuelTypeId: "",
      fuelType: "",   
      vehicleNo: ""
    });
    localStorage.removeItem("vehicle-selected");
    
  };


  // console.log("brand data",brandSelected, modelSelected, fuelSelected);
  console.log("model data", modelSelected);
  // console.log("fuel data",fuelSelected);
  return (
    <div className={className}>
    <div className="p-4 w-full">
      <div className=" flex flex-col rounded-md w-full flex-grow border-red-600">
        <div className="flex items-center space-x-2 px-2 py-2">
          <AiOutlineArrowLeft size={20} onClick={() => handleBackPress()} />
          <input
            type="text"
            value={brandSearch}
            className="px-3 py-2 w-full"
            placeholder={placeholder[count]}
            onChange={(e) => setBrandSearch(e.target.value)}
            onClick={() => setMenu(true)}
            ref={ref}
            disabled={selectionDone && fuelSelected} 
          />
        </div>
        {menu &&
          (!loading ? (
            count === 0 ? (
              <div className="flex h-[200px] overflow-auto flex-col">
                <div className="grid grid-cols-2 md:grid-cols-3 xl:grid-cols-3 p-3 gap-5">
                  {brands.map((brand) =>
                    brand.name
                      ?.toLowerCase()
                      .includes(brandSearch?.toLowerCase()) ? (
                      <div
                        key={brand._id}
                        onClick={() => handleBrandClick(brand)}
                      >
                        <VehicleIcon name={brand.name} icon={brand.logo} />
                      </div>
                    ) : null
                  )}
                </div>
              </div>
            ) : count === 1 ? (
              <div className="flex h-[200px] overflow-auto flex-col">
                <div className="flex flex-row">
                  {/* {brandSelected &&
                    brandSelected.models.map((model, i) =>
                      model.name
                        ?.toLowerCase()
                        .includes(brandSearch?.toLowerCase()) ? (
                        <div key={i} onClick={() => handleModelClick(model)}>
                          <VehicleIcon name={model.name} icon={model.photo} />
                        </div>
                      ) : null
                    )} */}
                    {/* {Array.isArray(modelSelected) && modelSelected.map((model,i)=>{               
                       return (
                        <div key={i} className="flex flex-col mr-4 cursor-pointer " onClick={() => handleModelClick(model)}>
                          <img src={model.photo} alt={model.name} className="w-20 h-20 object-cover mb-2" />
                          <p className="text-lg font-medium">{model.name}</p>
                        </div>
                       )
                      })} */}

                      {Array.isArray(modelList) && modelList.map((model,i)=>{               
                       return (
                        <div key={i} className="flex flex-col mr-4 cursor-pointer " onClick={() => handleModelClick(model)}>
                          <img src={model.photo} alt={model.name} className="w-20 h-20 object-cover mb-2" />
                          <p className="text-lg font-medium">{model.name}</p>
                        </div>
                       )
                      })}
                    
                </div>
              </div>
            ) : count === 2 ? (
              <div className="flex h-[200px] overflow-auto flex-col">
                <div className="flex flex-row">
                  {/* {modelSelected &&
                    modelSelected.fuelType &&
                    [modelSelected.fuelType].map((fuelType, i) =>
                      fuelType
                        ?.toLowerCase()
                        .includes(brandSearch?.toLowerCase()) ? (
                        <div key={i} onClick={() => handleFuelClick(fuelType)}>
                          <VehicleIcon
                            name={ fuelType }
                            icon={`./${fuelType.toLowerCase()}.jpg`}
                          />
                        </div>
                      ) : null
                    )} */}
                      {fuellist.map((fuel,i)=>{
                       
                       return (
                        <div key={i} className="flex flex-col mr-4 cursor-pointer " onClick={() => handleFuelClick(fuel.fuelType)}>
                          <img src={fuel.icon} alt={fuel.icon} className="w-20 h-20 object-cover mb-2" />
                          <p className="text-lg font-medium">{fuel.fuelType}</p>
                        </div>
                       )
                        
                       
                      })}

                </div>
              </div>
            ) : count === 3 && carno ? (
              <div className="flex h-[200px] overflow-auto flex-col">
                <TextField
                id="outlined-basic"
                type="text"
                label="Enter Vehicle RegNo."
                variant="outlined"
                className="w-full"
                style={{ marginTop: "16px" }}
                value={carnum}
                onChange={(e) => setCarNumber(e.target.value)}
              />
              </div>
            ) : null
          ) : null)}

      </div>

      {selectionDone &&
        (fuelSelected ? (
          <div
            className="border border-gray-300 rounded-lg overflow-hidden shadow-lg my-4 p-3 bg-white relative max-h-[12em]"
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
          >
            <div className="flex justify-between items-center mb-4">
              <p className="font-semibold text-lg">Selected Car:</p>
              {isHovered && (
                <FontAwesomeIcon
                  icon={faTrashAlt}
                  className="cursor-pointer text-red-500"
                  onClick={handleRemoveSelection}
                  title={isHovered ? "Remove Selected Car" : ""}
                />
              )}
            </div>
            <div className="grid grid-cols-4 gap-5">
              <div className="col-span-2 flex w-full justify-start h-[50%]">
                <img alt="" src={modelSelected.photo || copyData.model.photo} className='object-contain w-[80%] h-[80%]' />
              </div>
              <div className="col-span-2 flex w-full">
                <div className="flex flex-col justify-start ">
                  <p>
                    <span className="font-semibold">Brand:</span>{" "}
                    {brandSelected.name ? brandSelected.name : vehicleData.brandName || 'No brand available'}
                  </p>
                  <p>
                    <span className="font-semibold">Model:</span>{" "}
                    {modelSelected.name ? modelSelected.name : vehicleData.modelName || 'No model available'}
                  </p>
                  <p>
                    <span className="font-semibold">Fuel:</span> 
                    {fuelSelected ? fuelSelected : vehicleData.fuelType || 'No fuel available'}
                  </p>
                  {carno && <p>
                    <span className="font-semibold">Vehicle No:</span> 
                    {vehicleData.vehicleNo }
                  </p> }
                </div>
              </div>
            </div>
          </div>
        ) : copyData.fuel || (selectionDone && copyData.fuel && (!carno || (carno && copyData.vehicleNo))) ? (
          <div
            className="border border-gray-300 rounded-lg overflow-hidden shadow-lg my-4 p-4 bg-white relative"
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
          >
            <div className="flex justify-between items-center mb-4">
              <p className="font-semibold text-lg">Selected Car:</p>
              {isHovered && (
                <FontAwesomeIcon
                  icon={faTrashAlt}
                  className="cursor-pointer text-red-500"
                  onClick={handleRemoveSelection}
                />
              )}
            </div>
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-5 gap-4">
              <div className="col-span-1 md:col-span-3 flex justify-center items-center">
                <img
                  className="max-w-full h-auto rounded-md"
                  src={copyData.model.photo}
                  alt={copyData.model.name}
                />
              </div>
              <div className="col-span-1 md:col-span-2 flex flex-col justify-center">
                <p className="mb-2">
                  <span className="font-semibold">Brand:</span>{" "}
                  {copyData.brand.name}
                </p>
                <p className="mb-2">
                  <span className="font-semibold">Model:</span>{" "}
                  {copyData.model.name}
                </p>
                <p>
                  <span className="font-semibold">Fuel:</span> {copyData.fuel}
                </p>
                {carno && <p>
                  <span className="font-semibold">Vehicle No:</span> {copyData.vehicleNo}
                </p>}
              </div>
            </div>
          </div>
        ) : null)}
    </div>
    </div>
  );
});

export default VehicleSelector;

